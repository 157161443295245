import React, { useEffect, useState } from "react";
import {
  ILocation,
  IPatient,
  IRX,
  ICase,
  IPatientFiles,
  IBlobFile,
  IDicomSeries,
  ICaseInfo,
} from "../../../../services/api/models";
import {
  getDicomSeriesList,
  getBlobFilesList,
  getConnectCasesInfo,
  FetchTreatmentDataUrl,
} from "../../../../services/api/patientService";
import DicomSeriesCard from "../../Dicoms/DicomSeriesCard";
import BlobFileCard from "../../BlobFiles/BlobFileCard";
import {
  ButtonsDiv,
  DicomContainer,
  ExportCaseContainer,
  PartnerLogo,
  PatientFilesContainer,
  PatientLoaderContainer,
  PatientRXSContainer,
  RXHeader,
  StyledRXBox,
} from "./PatientDetails.styled";
import CaseStatus from "./CaseStatus/CaseStatus";
import { IconButton } from "@mui/material";
import { Spinner } from "../../Loader/Loader.styled";
import exportDicom from "../../../../assets/images/DICOM_export.svg";
import ExportCase from "../../ExportCase/ExportCase";
import getLoadedConfig from "../../../../services/appConfigService";
import { normalizeDateTime } from "../../../../utils/dateTimeHelper";
import { Tooltip } from "react-tooltip";
import TooltipMUI from "../../TooltipMUI/TooltipMUI";
import { IconsButton } from "../../../common/buttons.styled";
import DicomExport from "../../../svg-icons/DicomExport";
import { XmlReader } from "../../ExportCase/XMLParser";
import CaseFileCard from "../../BlobFiles/CaseFiles/CaseFileCard";
import useAuthService from "../../../../services/authService";
import NoDataCard from "../../NoDataCard/NoDataCard";
import {
  ErrorBox,
  ErrorMessage,
} from "../../../../pages/location-data/PatientList/PatientList.styled";

interface PatientDetailsProps {
  location: ILocation;
  patient: IPatient;
  activeTab: "history" | "cases";
}

type PatientFiles = IPatientFiles;
const PatientDetails: React.FC<PatientDetailsProps> = ({
  patient,
  location,
  activeTab,
}) => {
  const [patientFiles, setPatientFiles] = useState<PatientFiles[]>([]);
  const [patientBlobs, setPatientBlobs] = useState<IBlobFile[]>([]);
  const [patientCases, setPatientCases] = useState<ICase[]>([]);
  const [loadingInfo, setLoadingInfo] = useState(true);
  const { ExportCaseAvailability } = getLoadedConfig();
  const [showExport, setShowExport] = useState(false);
  const [selectedCase, setSelectedCase] = useState<ICase>();
  const [paymentStatus, setPaymentStatus] = useState<boolean>(false);
  const { isPaidLocation } = useAuthService();
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const checkPaymentStatus = async () => {
      const status = await isPaidLocation();
      setPaymentStatus(status);
    };

    checkPaymentStatus();
  }, [isPaidLocation]);

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setLoadingInfo(true);

      const fetchedRxs: IRX[] = [];
      const patientBlobFiles: IBlobFile[] = [];
      const sCases: ICase[] = [];
      const dCases: ICase[] = [];

      const allFiles: IPatientFiles[] = [];

      if (!patient || !location) {
        return;
      }

      try {
        // Fetch all dicoms
        const dicomResponse = await getDicomSeriesList(
          patient.recordId,
          location.account.region.apiUrl
        );

        dicomResponse.forEach(async (dicomSeries: IDicomSeries) => {
          // Add any dicom to allFiles list
          allFiles.push(dicomSeries);

          // Create a new RX
          const newRX: IRX = {
            seriesInstanceUid: dicomSeries.seriesInstanceUid,
            dicom: dicomSeries,
            attachedFiles: [],
          };

          // Add the new RX to the list
          fetchedRxs.push(newRX);
        });

        // Get all blobs
        const blobFileResponse = await getBlobFilesList(
          patient.recordId,
          location.account.region.apiUrl
        );

        blobFileResponse.forEach((blobFile: IBlobFile) => {
          // Add any blob to all file list
          allFiles.push(blobFile);

          //Add blobs not linked to a scan to patient blobs
          if (blobFile.seriesInstanceUid === null) {
            patientBlobFiles.push(blobFile);
          }

          // Associate any blob to any existing rx
          for (const rx of fetchedRxs) {
            if (rx.seriesInstanceUid === blobFile.seriesInstanceUid) {
              rx.attachedFiles.push(blobFile);
            }
          }
        });

        // Get connect cases from all rxs
        for (const rx of fetchedRxs) {
          // If the current rx is associated to a rx, fetch all connect cases associated to that dicom
          try {
            const casesInfoResponse = await getConnectCasesInfo(
              rx.dicom.recordId,
              location.account.region.apiUrl
            );

            // For any item in the response, create a new corresponding case based on current rx
            if (casesInfoResponse.length > 0) {
              casesInfoResponse.forEach((caseInfo: ICaseInfo) => {
                const newCase: ICase = {
                  rx: rx,
                  caseInfo: caseInfo,
                  caseFiles: caseInfo.caseFiles ? caseInfo.caseFiles : [],
                };

                // Add the new case to the list
                sCases.push(newCase);
              });
            } else {
              const prescriptionXML = await FetchTreatmentDataUrl(
                rx.dicom.recordId,
                location.account.region.apiUrl
              );
              const result = await XmlReader(prescriptionXML);
              if (result && "treatment" in result) {
                if (result.treatment.toothTreatments) {
                  const newCase: ICase = {
                    rx: rx,
                    caseInfo: null,
                  };
                  dCases.push(newCase);
                }
              }
            }
          } catch (error) {
            //TODO: Display error case
            console.error(
              "Error fetching or parsing associated connect cases: ",
              error
            );
          }
        }
      } catch (error: any) {
        if (error.statusCode === 404) {
          console.error("Patient not found ", error);
        } else {
          console.error("Error fetching dicoms: ", error);
          setIsError(true);
        }
      }

      sCases.sort(
        (a, b) =>
          normalizeDateTime(
            b.caseInfo?.submissions[b.caseInfo.submissions.length - 1].cdate ||
              b.rx.dicom.acquisitionDateTime
          ).getTime() -
          normalizeDateTime(
            a.caseInfo?.submissions[a.caseInfo?.submissions.length - 1].cdate ||
              a.rx.dicom.acquisitionDateTime
          ).getTime()
      );

      dCases.sort(
        (a, b) =>
          normalizeDateTime(b.rx.dicom.acquisitionDateTime).getTime() -
          normalizeDateTime(a.rx.dicom.acquisitionDateTime).getTime()
      );

      const cases = dCases.concat(sCases);

      setPatientBlobs(patientBlobFiles);
      setPatientFiles(allFiles);
      setPatientCases(cases);
      setLoadingInfo(false);
    };
    fetchData();
  }, [patient, location]);

  const onExport = (item: any) => {
    let patientCase: ICase | undefined;
    if ("rx" in item) {
      patientCase = item;
    } else {
      patientCase = patientCases.find(
        (caseItem) => caseItem.rx.seriesInstanceUid === item.seriesInstanceUid
      );
    }

    if (patientCase) {
      setSelectedCase(patientCase);
    } else {
      const attachedFiles = patientFiles.filter(
        (file) =>
          !file.acquisitionDateTime &&
          file.seriesInstanceUid === item.seriesInstanceUid
      ) as IBlobFile[];
      const defaultCase: ICase = {
        rx: {
          seriesInstanceUid: item.seriesInstanceUid,
          dicom: item,
          attachedFiles: attachedFiles,
        },
        caseInfo: null,
      };
      setSelectedCase(defaultCase);
    }

    setShowExport(true);
  };

  if (loadingInfo) {
    return (
      <PatientLoaderContainer>
        <Spinner />
      </PatientLoaderContainer>
    );
  }

  if (!loadingInfo && isError) {
    return (
      <ErrorBox>
        <ErrorMessage>
          Something went wrong! <br />
          Please try again
        </ErrorMessage>
      </ErrorBox>
    );
  }

  const formatUTCDate = (inputUTCStringDate: string) => {
    const utcDate = new Date(inputUTCStringDate);
    const offsetMinutes = new Date().getTimezoneOffset();
    const localDate = new Date(utcDate.getTime() - offsetMinutes * 60000);
    const year = localDate.getFullYear().toString();
    const month = localDate.getMonth() + 1;
    const day = localDate.getDate();
    const hours = localDate.getHours();
    const minutes = localDate.getMinutes();
    const seconds = localDate.getSeconds();

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <>
      {activeTab === "history" && (
        <PatientFilesContainer>
          {!paymentStatus ? (
            <NoDataCard
              title="Subscription Required"
              message="Access to this feature requires a paid account. Please upgrade to access your files."
            />
          ) : (
            patientFiles
              .sort(
                (a: any, b: any) =>
                  normalizeDateTime(
                    b.acquisitionDateTime ||
                      formatUTCDate(
                        b.fileLastModifiedDateTime || b.recordLastUpdated
                      )
                  ).getTime() -
                  normalizeDateTime(
                    a.acquisitionDateTime ||
                      formatUTCDate(
                        a.fileLastModifiedDateTime || a.recordLastUpdated
                      )
                  ).getTime()
              )
              .map((file: any) => {
                if (file.fileType) {
                  return (
                    <BlobFileCard
                      location={location}
                      blobFile={file}
                      key={file.recordId}
                    />
                  );
                } else {
                  return (
                    <DicomContainer key={file.recordId}>
                      {ExportCaseAvailability && (
                        <ExportCaseContainer>
                          <TooltipMUI title={["Export Case"]} themeColor="gray">
                            <IconsButton onClick={() => onExport(file)}>
                              <DicomExport />
                            </IconsButton>
                          </TooltipMUI>
                        </ExportCaseContainer>
                      )}
                      <DicomSeriesCard
                        location={location}
                        key={file.recordId}
                        dicomSeries={file}
                        showDownload
                      />
                    </DicomContainer>
                  );
                }
              })
          )}
        </PatientFilesContainer>
      )}

      {activeTab === "cases" && (
        <PatientRXSContainer>
          {patientCases.map((patientCase) => (
            <StyledRXBox
              key={
                patientCase.caseInfo?.caseId || patientCase.rx.seriesInstanceUid
              }
            >
              <RXHeader>
                {patientCase.caseInfo?.caseId && (
                  <p>
                    Case ID: <b>{patientCase.caseInfo?.caseId || "-"}</b>
                  </p>
                )}
                {patientCase.caseInfo?.laboratory.laboratoryType === 20 ? (
                  <PartnerLogo
                    src={patientCase.caseInfo?.laboratory.logo}
                    alt={patientCase.caseInfo?.laboratory.laboratoryName}
                  />
                ) : (
                  <p>
                    Laboratory:{" "}
                    <b>{patientCase.caseInfo?.laboratory.email || "-"}</b>
                  </p>
                )}
                <ButtonsDiv>
                  {patientCase.caseInfo && (
                    <CaseStatus caseData={patientCase} addDatesInfo />
                  )}
                  {ExportCaseAvailability && !patientCase.caseInfo && (
                    <IconButton onClick={() => onExport(patientCase)}>
                      <img
                        src={exportDicom}
                        alt="Send case"
                        data-tooltip-id="exportIcon"
                        data-tooltip-content="Export case"
                        data-tooltip-place="bottom"
                      />
                      <Tooltip id="exportIcon" className="iconTooltip" />
                    </IconButton>
                  )}
                </ButtonsDiv>
              </RXHeader>

              {patientCase.rx.dicom && (
                <DicomSeriesCard
                  location={location}
                  dicomSeries={patientCase.rx.dicom}
                  showDownload
                />
              )}

              {patientCase.caseFiles &&
                patientCase.caseFiles.map((caseFile) => (
                  <CaseFileCard key={caseFile.id} caseFile={caseFile} />
                ))}
            </StyledRXBox>
          ))}
        </PatientRXSContainer>
      )}
      {showExport && selectedCase && (
        <ExportCase
          open={showExport}
          onClose={() => setShowExport(false)}
          dicomSeries={selectedCase.rx.dicom}
          location={location}
          rx={selectedCase.rx}
          patient={patient}
          patientBlobs={patientBlobs}
          patientFiles={patientFiles}
        />
      )}
    </>
  );
};

export default PatientDetails;
