import React, { useEffect, useState } from "react";
import {
  MainContainer,
  SubscriptionContainer,
  HeaderContainer,
  BenifitsContainer,
  ExtraNotesContainer,
  GetStartedContainer,
  SuccessMsgContainer,
  MsgBox,
  LocationButton,
} from "./GetStarted.styled";
import ProductCard from "./ProductCard";
import { ILocation } from "../../../services/api/models";
import useAuthService from "../../../services/authService";
import { useNavigate } from "react-router-dom";

const GetStarted: React.FC = () => {
  const [freeTrialCreated, setFreeTrialCreated] = useState(false);
  const [reloadedLocation, setReloadedLocation] = useState<ILocation>();
  const navigate = useNavigate();

  const { reloadCurrentLocation } = useAuthService();

  useEffect(() => {
    const fetchLocationDetails = async () => {
      const locationsData = await reloadCurrentLocation();
      setReloadedLocation(locationsData);
    };
    fetchLocationDetails();
  }, [reloadCurrentLocation]);

  const handleFreeTrialCreated = (created: boolean): void => {
    setFreeTrialCreated(created);
  };

  const viewLocation = async () => {
    navigate(`/location/${reloadedLocation?.recordId}`);
  };

  return (
    <MainContainer>
      {freeTrialCreated ? (
        <GetStartedContainer>
          <SuccessMsgContainer>
            <MsgBox>
              <h3>
                Thank you for subscribing to DEXIS IS Connect Cloud Premium
                plan.
              </h3>
              <p>You will now be redirected to the patients list tab.</p>
              <LocationButton onClick={() => viewLocation()}>Ok</LocationButton>
            </MsgBox>
          </SuccessMsgContainer>
        </GetStartedContainer>
      ) : (
        <GetStartedContainer>
          <HeaderContainer>
            <h4>
              Get Started on the Premium Tier of IS Connect Cloud and enjoy all
              the benefits:
            </h4>
          </HeaderContainer>
          <SubscriptionContainer>
            <BenifitsContainer>
              <ul>
                <li key={"benifit1"}>
                  Workflows can be started while the patient is chairside and
                  completed at a later date, even on another computer inside or
                  outside of the practice. Whether you're at the office or at
                  home, cloud access simplifies your workflow. No longer
                  constrained by physical files or office hours, you can access
                  patient scans effortlessly.
                </li>
                <li key={"benifit2"}>
                  Secure access control for accounts, enabling practices within
                  the same organization to efficiently access data from multipe
                  locations.
                </li>
                <li key={"benifit3"}>
                  Upload, storage and download of the patient's scanned DICOM,
                  PLY and STL files.
                </li>
                <li key={"benifit4"}>
                  A web accessible mesh viewer enables viewing of the datasets
                  directly from the cloud, further improving your ability to
                  respond quickly to emergencies and provide remote
                  consultations.
                </li>
              </ul>
            </BenifitsContainer>
            <ProductCard handleFreeTrialCreated={handleFreeTrialCreated} />
          </SubscriptionContainer>
          <ExtraNotesContainer>
            <p>
              {" "}
              *Billed annually
              <br />
              Up to 1TB of data storage
            </p>
          </ExtraNotesContainer>
        </GetStartedContainer>
      )}
    </MainContainer>
  );
};
export default GetStarted;
