import styled from "styled-components";
import { media } from "../../../theme";
import { Dialog } from "@mui/material";
import { colors } from "../../../theme";

export const CaseDialog = styled(Dialog)`
  ${media.phoneL} {
    & div[role="dialog"] {
      width: 100%;
      min-height: 100vh;
      margin: 0;
      border-radius: 0;
    }
  }

  .css-tlc64q-MuiPaper-root-MuiDialog-paper {
    background-color: ${colors["gray-2"]};
  }
`;

export const FileSection = styled.div`
  padding: 10px;
  margin-bottom: 5px;
  background-color: ${colors["gray-2"]};
`;

export const SectionTitle = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-bottom: 10px;
  color: ${colors["gray-7"]};

  hr {
    flex: 1;
    border: none;
    height: 1px;
    background-color: ${colors["gray-5"]};
    margin: 0 10px;
  }

  span {
    white-space: nowrap;
  }
`;

export const FileItemsContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
`;

export const FileItem = styled.div<{ selected: boolean }>`
  padding: 5px;
  margin: 5px;
  cursor: pointer;
  outline: ${({ selected }) =>
    selected ? "2px solid orange" : "1px solid gray"};

  background-color: white;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;

  & .thumbnailImg {
    box-sizing: border-box;
    overflow: hidden;
    width: inherit;
    height: 80px;
    object-fit: cover;
    margin: 0 auto;
  }

  & .thumbnailFile {
    box-sizing: border-box;
    overflow: hidden;
    width: inherit;
    height: 90px;
    object-fit: contain;
    max-height: 65px;
    max-width: 65px;
    margin: 10px auto;
  }

  & span {
    display: block;
    width: 100%;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
  }
`;

export const FileUpload = styled.div`
  margin: 5px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const BackButton = styled.button`
  width: 160px;
  padding: 10px 20px;
  background-color: ${colors["gray-4"]};
  color: black;
  border: none;
  cursor: pointer;
`;

export const SelectButton = styled.button`
  width: 160px;
  padding: 10px 20px;
  margin-left: 42%;
  background-color: ${colors["orange-1"]};
  color: white;
  border: none;
  cursor: pointer;

  &:disabled {
    border: 1px solid ${colors["grey-color"]};
    color: ${colors["grey-color"]};
    background-color: ${colors["background-grey-light-color"]};
  }
`;
