import React, { useEffect, useState, useCallback } from "react";
import {
  DicomAppsButtonStyled,
  ModalBackDrop,
  ModalContent,
  CloseButton,
  LaunchControl,
  LaunchedButton,
  MenuItemStyled,
} from "./DicomAppsButton.styled";
import Loader from "../Loader/Loader";
import AppsIcon from "../../svg-icons/AppsIcon";
import { Menu } from "@mui/material";
import ModelIcon from "../../svg-icons/Model";

interface Props {
  locationId: string;
  seriesInstanceUid: string;
  seriesId: string;
  regionalUrl: string;
  userOid: string;
}

const DicomAppsButton: React.FC<Props> = ({
  locationId,
  seriesInstanceUid,
  regionalUrl,
  seriesId,
  userOid,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [doNotShowAgainCheckbox, setDoNotShowAgainCheckbox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleDicomAppsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  }, []);

  useEffect(() => {
    if (modalOpen) {
      handleMenuClose();
    }
  }, [modalOpen, handleMenuClose]);

  const handleOpenAppClient = async () => {
    setLoading(true);
    try {
      const modelProtocol = `cloudbridge://?locationId=${locationId}&seriesInstanceUid=${seriesInstanceUid}&regionalUrl=${regionalUrl}&seriesId=${seriesId}&userOid=${userOid}&software=Model&minimalVersion=1.0.114.0`;
      openCustomProtocol(modelProtocol);

      setTimeout(() => {
        setLoading(false);
      }, 10000);
    } catch (error) {
      console.error("Error getting authentication token:", error);
    }
    setModalOpen(false);
  };

  const openCustomProtocol = (url: string) => {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = url;
    document.body.appendChild(iframe);
    setTimeout(() => {
      document.body.removeChild(iframe);
    }, 1000);
  };

  const handleLaunchClick = () => {
    if (doNotShowAgainCheckbox) localStorage.setItem("doNotShowAgain", "true");
    handleOpenAppClient();
  };

  const handleClickOnModel = () => {
    const storedDonotShowAgain =
      localStorage.getItem("doNotShowAgain") === "true";
    setDoNotShowAgainCheckbox(storedDonotShowAgain);
    storedDonotShowAgain ? handleOpenAppClient() : setModalOpen(true);
  };

  return (
    <>
      <DicomAppsButtonStyled onClick={handleDicomAppsClick}>
        <AppsIcon />
      </DicomAppsButtonStyled>

      <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose}>
        <MenuItemStyled onClick={handleClickOnModel}>
          <ModelIcon />
          <span>IS Model</span>
        </MenuItemStyled>
      </Menu>

      {loading && (
        <ModalBackDrop>
          <ModalContent>
            <span>IS Connect Cloud Bridge is launching, please wait...</span>
            <Loader />
          </ModalContent>
        </ModalBackDrop>
      )}
      {modalOpen && (
        <ModalBackDrop>
          <ModalContent>
            <CloseButton onClick={() => setModalOpen(false)}>X</CloseButton>
            <h2>IS Connect Cloud Bridge is required to Launch</h2>
            <p>
              IS Connect Cloud Bridge is required to launch on prem IS Model{" "}
              <br /> it can be downloaded from the setting's App section
            </p>
            <LaunchControl>
              <label>
                <input
                  type="checkbox"
                  checked={doNotShowAgainCheckbox}
                  onChange={() =>
                    setDoNotShowAgainCheckbox(!doNotShowAgainCheckbox)
                  }
                />
                Do not show again if it is already installed
              </label>
              <LaunchedButton onClick={() => handleLaunchClick()}>
                Launch with IS Model
              </LaunchedButton>
            </LaunchControl>
          </ModalContent>
        </ModalBackDrop>
      )}
    </>
  );
};
export default DicomAppsButton;
