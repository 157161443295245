import styled from "styled-components";
import { colors, media } from "../../../theme";
import { OrangeButton } from "../../common/buttons.styled";

export const MainContainer = styled.main`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const GetStartedContainer = styled.main`
  display: flex;
  flex-direction: column;
  padding: 30px;
  min-height: calc(100vh - 15vh);

  ${media.tablet} {
    min-height: calc(100vh - 17vh);
  }
`;

export const BenifitsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 600px;

  ${media.tablet} {
    min-height: calc(100vh - 60vh);
  }
`;

export const SubscriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const HeaderContainer = styled.div`
  text-align: left;
`;

export const ExtraNotesContainer = styled.div`
  text-align: left;
  font-size: small;
`;

export const SuccessMsgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
`;

export const MsgBox = styled.div`
  border: 1px solid ${colors["gray-5"]};
  border-radius: 20px;
  width: 350px;
  padding: 25px;
  text-align: justify;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const LocationButton = styled(OrangeButton)`
  font-size: large;
`;
