import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Wait from "../../components/shared/Wait/Wait";
import { ErrorBox, ErrorMessage, MainContainer } from "./index.styled";
import useAuthService from "../../services/authService";

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [loadingLocations, setLoadingLocations] = useState(true);
  const [error, setError] = useState("");
  const authService = useAuthService();

  useEffect(() => {
    // Check if current user already has access to a location
    const fetchLocations = async () => {
      try {
        const currentLocation = await authService.getCurrentLocation();
        const locationPlan = await authService.getLocationPlan();
        const userEmail = await authService.getUserEmail();
        const currentUser = currentLocation?.userLocations.find(
          (user) => user.user.emailAddress === userEmail
        );
        const isAdmin = currentUser?.role === "LocationAdministrator";

        setLoadingLocations(false);
        // Redirect user to subscription page if user hasn't chosen free plan or start free trial
        if (
          isAdmin &&
          currentLocation &&
          !localStorage.getItem(`stayFree:${currentLocation.recordId}`) &&
          locationPlan.status === "Free"
        ) {
          navigate(`/subscription`);
        } else if (currentLocation) {
          // Redirect user to his first location data
          navigate(`/location/${currentLocation.recordId}`);
        } else {
          // invite user to create his location => redirect to location management
          navigate(`/location`);
        }
      } catch (error) {
        setLoadingLocations(false);
        setError("Something went wrong. Please refresh the page!");
        console.error("Error fetching locations:", error);
      }
    };
    fetchLocations();
  }, [navigate, authService]);

  return (
    <MainContainer>
      {loadingLocations && <Wait />}

      {error && (
        <ErrorBox>
          <ErrorMessage>{error}</ErrorMessage>
        </ErrorBox>
      )}
    </MainContainer>
  );
};

export default Home;
