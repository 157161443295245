import countriesData from "./../assets/countries.json";

interface Country {
  name: string;
  iso: string;
}

export const IsoCodeArray = (countriesData as Country[])
  .map((country) => country.iso);
export const CountryArray = countriesData as Country[];
