import styled from "styled-components";
import { OrangeButton } from "../../../common/buttons.styled";
import { colors, media } from "../../../../theme";

export const RegistrationRequestContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  min-width: 500px;
  min-height: 340px;
  margin: auto;
  padding: 15px;
  border-radius: 8px;
  background-color: ${colors["background-grey-light-color"]};
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  letter-spacing: 0.5px;

  & h2,
  p:first-of-type {
    padding: 0 20px;
    color: black;
  }

  ${media.laptop} {
    width: 70%;

    & h2 {
      font-size: 24px;
    }
  }

  ${media.tablet} {
    width: 80%;

    & h2 {
      font-size: 22px;
    }

    & p {
      font-size: 18px;
    }
  }

  ${media.phoneL} {
    width: 90%;
    min-width: unset;
    min-height: unset;

    & h2 {
      font-size: 20px;
      padding: 0;
    }

    & p {
      font-size: 16px;

      &:first-of-type {
        padding: 0;
      }
    }
  }

  ${media.phoneS} {
    width: 95%;

    & h2 {
      font-size: 18px;
    }

    & p {
      font-size: 15px;
    }
  }
`;

export const RequestForm = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ErrorMessage = styled.p`
  color: ${colors["error-red-text"]};
  height: 20px;
  margin-top: 10px;
  text-align: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
`;

export const SubmitButton = styled(OrangeButton)`
  align-self: flex-end;
  letter-spacing: 1px;

  ${media.phoneL} {
    width: 80px;
    height: 45px;
  }

  ${media.phoneS} {
    height: 40px;
  }
`;

export const CancelButton = styled.button`
  width: 100px;
  height: 50px;
  padding: 4px 10px;
  color:${colors["gray-15"]}; ;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s ease;
  
  ${media.phoneL} {
    width: 80px;
    height: 45px;
  }

  ${media.phoneS} {
    height: 40px;
  }
`;
