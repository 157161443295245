import React, { useState, useEffect } from "react";
import {
  PlanCardContainer,
  PlanInfo,
  ButtonsContainer,
} from "./PlanCard.styled";
import { Button } from "@mui/material";
import useAuthService from "../../../services/authService";
import { ILocation } from "../../../services/api/models";
import { Link  } from "react-router-dom";
import { cancelSubscription } from "../../../services/api/subscriptionService";

interface PlanCardProps {
  onChangePlan: () => void;
  location: ILocation | undefined;
}

const PlanCard: React.FC<PlanCardProps> = ({ onChangePlan, location }) => {
  const { reloadCurrentLocation, getLocationPlan } = useAuthService();
  const [paymentStatus, setPaymentStatus] = useState<string | null>(null);
  const [planActive, setPlanActive] = useState<boolean | null>(null);
  const [planCancelDialog, setPlanCancelDialog] = useState<boolean>(false);

  useEffect(() => {
    const checkPaymentStatus = async () => {
      await reloadCurrentLocation();
      const plan = await getLocationPlan();
      setPaymentStatus(plan.status);
      setPlanActive(plan.active);
    };
    checkPaymentStatus();
  }, [reloadCurrentLocation, getLocationPlan]);

  const isFreeUser = paymentStatus === "Free";

  const planTransaction = location?.planTransactions?.[0];

  const expirationDate = planTransaction
    ? new Date(planTransaction.expiresOn).toLocaleDateString()
    : "-";

  const cancelPlan = async () => {

    if (location !== undefined) {
      try {
        setPlanCancelDialog(false)
        await cancelSubscription(location.recordId);
        await reloadCurrentLocation();
        const plan = await getLocationPlan();
        setPaymentStatus(plan.status);
        setPlanActive(plan.active);
        } catch (error) {
        console.error("Error cancelling the subscription: ", error);
      }
    }
  }

  return (
    <>
      {planCancelDialog ? (
        <>
          <PlanCardContainer>
            <PlanInfo>
              <h2>Change Plan</h2>
              <p>The plan will change to Free on {expirationDate}.</p>
              <p></p>
              <p>You will benefit of all IS Connect Cloud features until that date.</p>
            </PlanInfo>
            <ButtonsContainer>
              <Button onClick={cancelPlan}>Confirm</Button>
              <Button onClick={() => {setPlanCancelDialog(false)}}>Abort</Button> 
            </ButtonsContainer>
          </PlanCardContainer >
        </>
      ) : (
        <>
          {isFreeUser ? (
            <PlanCardContainer>
                <PlanInfo>
                  <h1>DEXIS IS Connect Cloud Free</h1>
                  <span>
                    By subscribing to Premium plan, unlock all product capabilities.
                  </span>
                </PlanInfo>
                <ButtonsContainer>
                  <Link to="/subscription?getStarted=true">
                    <Button onClick={onChangePlan}>Change Plan</Button>
                  </Link>
                </ButtonsContainer>
              </PlanCardContainer >
            ) : (
              <PlanCardContainer>
                <PlanInfo>
                  <h1>DEXIS IS Connect Cloud Premium</h1>
                  <span>Premium until: {expirationDate}</span>
                </PlanInfo>
                <ButtonsContainer>
                  {planActive ? (
                    <Button onClick={() => {setPlanCancelDialog(true)}} >Cancel Plan</Button>
                  ) : (
                    <Link to="/subscription?getStarted=true">
                      <Button onClick={onChangePlan}>Resume Plan</Button>
                    </Link>
                  )}
                </ButtonsContainer>
              </PlanCardContainer>
            )}
        </>
      )}
    </>
  );
};

export default PlanCard;
