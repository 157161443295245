import axios from "axios";
import UrlAssembler from "url-assembler";

interface ICloudScopes {
  readonly globalScopes: string[];
  readonly regionalScopes: string[];
  readonly connectScopes: string[];
}

interface IAzureAdConfig {
  loginDomain?: string;
  readonly clientId: string;
  readonly tenantName: string;
  readonly policies: {
    login: string;
  };
  readonly scopes: ICloudScopes;
}

export interface IAppConfig {
  readonly name: string;
  readonly globalApiBaseUrl: string;
  readonly globalApiUrl: UrlAssembler;
  readonly globalApiODataUrl: UrlAssembler;
  readonly meshviewerUrl: string;
  buildVersion: string;
  readonly azureAd: IAzureAdConfig;
  releaseDate: string;
  ExportCaseAvailability: boolean;
  readonly FreeTrialDuration: number;
  HideAttachmentButton: boolean;
  readonly connectBaseUrl: string;
  readonly connectUrl: UrlAssembler;
  readonly connectODataUrl: UrlAssembler;
  readonly cloudBridgeInstallerUrl: string;
}

type IAppConfigJson = {
  name: string;
  globalApiBaseUrl: string;
  meshviewerUrl: string;
  buildVersion: string;
  azureAd: IAzureAdConfig;
  releaseDate: string;
  ExportCaseAvailability: boolean;
  FreeTrialDuration: number;
  HideAttachmentButton: boolean;
  connectBaseUrl: string;
  cloudBridgeInstallerUrl: string;
};

export class AppConfig implements IAppConfig {
  readonly name: string;
  readonly meshviewerUrl: string;
  readonly globalApiBaseUrl: string;
  readonly globalApiUrl: UrlAssembler;
  readonly globalApiODataUrl: UrlAssembler;
  readonly buildVersion: string;
  readonly azureAd: IAzureAdConfig;
  readonly releaseDate: string;
  readonly ExportCaseAvailability: boolean;
  readonly FreeTrialDuration: number;
  readonly HideAttachmentButton: boolean;
  readonly connectBaseUrl: string;
  readonly connectUrl: UrlAssembler;
  readonly connectODataUrl: UrlAssembler;
  readonly cloudBridgeInstallerUrl: string;

  constructor(json: IAppConfigJson) {
    console.assert(
      json.name &&
      json.globalApiBaseUrl &&
      json.connectBaseUrl &&
      json.buildVersion &&
      json.meshviewerUrl
    );
    this.name = json.name;
    this.meshviewerUrl = json.meshviewerUrl;
    this.connectBaseUrl = json.connectBaseUrl;
    this.connectUrl = new UrlAssembler(json.connectBaseUrl).segment(
      "api/v1/"
    );
    this.connectODataUrl = new UrlAssembler(json.connectBaseUrl).segment(
      "odata/v1/"
    );
    this.globalApiBaseUrl = json.globalApiBaseUrl;
    const url = new UrlAssembler(json.globalApiBaseUrl);
    this.globalApiUrl = url.segment("api/v1/");
    this.globalApiODataUrl = url.segment("odata/v1/");
    this.cloudBridgeInstallerUrl = json.cloudBridgeInstallerUrl;
    this.buildVersion = json.buildVersion;
    this.releaseDate = json.releaseDate;
    this.ExportCaseAvailability = json.ExportCaseAvailability;
    this.FreeTrialDuration = json.FreeTrialDuration;
    this.HideAttachmentButton = json.HideAttachmentButton;
    this.azureAd = json.azureAd;
    if (!this.azureAd.loginDomain)
      this.azureAd.loginDomain = `${this.azureAd.tenantName}.b2clogin.com`;
  }
}

let loadedConfig: IAppConfig;

// Load config file from "/public/config.json"
const configFileName = "config.json";
const configUrl = process.env.PUBLIC_URL + "/" + configFileName;

async function internalLoadConfig() {
  try {
    const configResponse = await axios.get<IAppConfigJson>(configUrl);
    loadedConfig = new AppConfig(configResponse.data);
    return loadedConfig;
  } catch (e) {
    throw new Error(`Failed to load app configuration (${configFileName})`);
  }
}

const configLoaderPromise = internalLoadConfig();
// The following isn't strictly needed -- we could just export `cachedConfigLoader` directly.
// However, making it a function makes for a better API. Ex: Instead of `loadConfig.then(...)`, caller will need to use `loadConfig().then(...)`
const loadConfig = () => configLoaderPromise;

function getLoadedConfig() {
  if (!loadedConfig) {
    throw new Error("App configuration hasn't been loaded!");
  }

  return loadedConfig;
}

export { getLoadedConfig as default, loadConfig };
