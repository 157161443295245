import React, { useState } from "react";
import {
  ButtonsContainer,
  CancelButton,
  ErrorMessage,
  RequestForm,
  RegistrationRequestContainer,
  SubmitButton,
} from "./RequestAccessForm.styled.";
import { registrationRequest } from "../../../../services/api/registrationRequestService";
import useAuthService from "../../../../services/authService";
import usePersistedQueryParams from "../../../../shared/usePersistedAppQueryParams";

interface RequestAccessStateProps {
  countryCode: string | undefined;
}

const RequestAccessForm: React.FC<RequestAccessStateProps> = ({
  countryCode,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [embeddedRegistration, setIsEmbeddedRegistration] = useState(false);
  const { embedded } = usePersistedQueryParams();
  const authService = useAuthService();

  const handleCancelButton = async () => {
    embedded ? setIsEmbeddedRegistration(true) : authService.logout();
  };

  const storeRegistrationRequest = async () => {
    if (countryCode) {
      try {
        await registrationRequest(countryCode);
        embedded ? setIsEmbeddedRegistration(true) : authService.logout();
      } catch (err: any) {
        setErrorMessage("Something went wrong! Please try again!");
      }
    }
  };

  if (embeddedRegistration) {
    return (
      <RegistrationRequestContainer>
        <RequestForm>
          <p>
            Thank you for your interest in DEXIS IS Connect Cloud <br /> You can
            now close this window
          </p>
        </RequestForm>
      </RegistrationRequestContainer>
    );
  }

  return (
    <RegistrationRequestContainer>
      <RequestForm>
        <h2>IS Connect Cloud is not yet available in the selected country</h2>

        {embedded && !embeddedRegistration ? (
          <p>
            By clicking Ok your email will be stored and you will be notified
            when
            <br />
            IS Connect Cloud becomes available in your country.
          </p>
        ) : (
          <p>
            By clicking Ok your email will be stored and you will be notified
            when IS Connect Cloud <br />
            becomes available in your country. You will then be redirected to
            the Homepage.
          </p>
        )}

        <ErrorMessage>{errorMessage}</ErrorMessage>

        <ButtonsContainer>
          <CancelButton onClick={handleCancelButton}>Cancel</CancelButton>
          <SubmitButton onClick={storeRegistrationRequest}>Ok</SubmitButton>
        </ButtonsContainer>
      </RequestForm>
    </RegistrationRequestContainer>
  );
};

export default RequestAccessForm;
