import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { createLocation } from "../../../../services/api/locationService";
import {
  CountryOption,
  ICreationLocation,
} from "../../../../services/api/models";
import useAuthService from "../../../../services/authService";
import {
  CreationFormContainer,
  NextStepButton,
  EmbeddedLocationContainer,
  EmbeddedMessagesContainer,
  UserTypeSelection,
  SelectionButton,
  SelectionsContainer,
  WelcomeMessages,
  TermsOfUsePopUp,
  CloseButton,
  TermsOfUseLink,
} from "./LocationForm.styled";
import usePersistedQueryParams from "../../../../shared/usePersistedAppQueryParams";
import greenCheck from "../../../../assets/images/green-check.png";
import redError from "../../../../assets/images/red-error.png";
import Wait from "../../Wait/Wait";
import TermsOfUsePage from "../../../../pages/terms-of-use";
import RequestAccessForm from "../RequestAccessForm/RequestAccessForm";
import { FormHelperText, Input, FormControl } from "@mui/material";
import { ErrorOutline, Done, Close } from "@mui/icons-material";
import { CountryArray } from "./../../../../utils/countries";
import Flag from "react-world-flags";
import Select, { SingleValue } from "react-select";
import { HttpError } from "../../../../services/api/http";
interface LocationFormProps {
  onLocationCreate: () => void;
}

const LocationForm: React.FC<LocationFormProps> = ({ onLocationCreate }) => {
  const [practiceName, setPracticeName] = useState(`My practice`);
  const [name, setName] = useState("");
  const [userType, setUserType] = useState("");
  const [locationCreated, setLocationCreated] = useState(false);
  const [loadingCreatingLocation, setLoadingCreatingLocation] = useState(false);
  const authService = useAuthService();
  const { embedded } = usePersistedQueryParams();
  const [nameError, setNameError] = useState("");
  const [country, setCountry] = useState<SingleValue<CountryOption>>(null);
  const [countryError, setCountryError] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [showNextStep, setShowNextStep] = useState("");
  const [openTermsOfUsePopUp, setOpenTermsOfUsePopUp] = useState(false);
  const [isCountryAllowed, setIsCountryAllowed] = useState(true);

  useEffect(() => {
    const fetchUserDetails = async () => {
      // As name, we first use firstName; if not available, we use lastName; if not available neither, we let name empty
      const userFirstName = await authService.getFirstName();
      const userLastName = await authService.getLastName();
      const name = userFirstName
        ? userFirstName
        : userLastName
          ? userLastName
          : "";
      const practiceName = name ? `${name}'s practice` : "My practice";
      setName(name);
      setPracticeName(practiceName);
      setIsCountryAllowed(true);
    };
    fetchUserDetails();
  }, [authService]);

  const createNewLocation = async (location: ICreationLocation) => {
    try {
      setLoadingCreatingLocation(true);
      await createLocation(location);
      setLoadingCreatingLocation(false);
      return true;
    } catch (error: any) {
      setLoadingCreatingLocation(false);
      console.error("Error creating location:", error);
      const httpError = error as HttpError;
      if (httpError.statusCode === 400) {
        const errorMessage = httpError.message;
        if (errorMessage === "ERROR_COUNTRY_NOT_SUPPORTED") {
          setIsCountryAllowed(false);
        } else {
          setHasError(true);
        }
      }
      return false;
    }
  };

  const validateFieldLength = (value: string) => {
    value = value.trim();
    const minLength = 3;
    const maxLength = 64;
    if (value.length < minLength) {
      return "Practice Name is too short";
    }
    else if (value.length > maxLength) {
      return "Practice Name is too long";
    } else {
      return "";
    }
  };

  const handleCreateLocation = async () => {
    if (nameError || countryError || !formValid) {
      return;
    }

    const location: ICreationLocation = {
      name: practiceName.trim(),
      country: country!.value,
    };

    const locationCreated = await createNewLocation(location);
    if (!locationCreated) {
      return
    }

    if (embedded !== "true") {
      onLocationCreate();
    } else {
      setLocationCreated(true);
    }

    //Reset the fields after submission
    setPracticeName(`${practiceName}`);
    setNameError("");
    setFormValid(false);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let error = validateFieldLength(value);
    setNameError(error);
    setPracticeName(value);
  };

  const handleCountryChange = (country: SingleValue<CountryOption>): void => {
    setCountry(country);
    if(!country || !country.value || country.value.length !== 2){
      setCountryError("Please select a country");
    } else {
      setCountryError("");
    }
  }

  useEffect(() => {
    const validateForm = () => {
      setFormValid(
        (validateFieldLength(practiceName) === "") &&
        (country?.value.length === 2)
      );
    };
    validateForm();
  }, [practiceName, country]);

  const handleTermsOfUsePopUp = (): void => {
    if (embedded === "true") {
      setOpenTermsOfUsePopUp(true);
    }
  };

  const handleUserTypeSelect = (type: string) => {
    setUserType(type);
  };

  if (loadingCreatingLocation) {
    return <Wait rightOrBottomText={"Creating account"} />;
  }

  if (hasError) {
    return (
      <div className="error-message">
        <p>
          <ErrorOutline />
          <span>Error creating location, please try again</span>
        </p>
      </div>
    );
  }

  const options: CountryOption[] = CountryArray.map((country) => ({
    value: country.iso,
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Flag
          code={country.iso}
          style={{ width: 20, height: 15, marginRight: 10 }}
        />
        <span>{country.name}</span>
      </div>
    ),
    labelText: country.name,
  }));

  return (
    <>
      {embedded === "true" && locationCreated ? (
        <EmbeddedLocationContainer>
          <img alt="green check validation" src={greenCheck} />
          <EmbeddedMessagesContainer>
            <b>Account created</b>
            <p>
              You can close this window and start using DEXIS IS Connect Cloud
              services in IS ScanFlow.
            </p>
          </EmbeddedMessagesContainer>
        </EmbeddedLocationContainer>
      ) : (
        <>
          {showNextStep !== "show" ? (
            <UserTypeSelection>
              <WelcomeMessages>
                <h1>Welcome to DEXIS IS Connect Cloud{name && `, ${name}`}!</h1>
                <ul>
                  <li>
                    <span>Seamless workflow:</span> Unrestricted by location or
                    time, effortlessly access patient scans via cloud
                    technology.
                  </li>
                  <li>
                    <span>Anytime collaboration:</span> Share DEXIS scanner data
                    easily via the cloud for convenient case collaboration with
                    partners.
                  </li>
                  <li>
                    <span>Web viewer:</span> View patient files online to
                    enhance your response to emergencies and provide remote
                    consultations.
                  </li>
                </ul>
                <p>Are you a practice administrator?</p>
              </WelcomeMessages>
              <SelectionsContainer>
                <SelectionButton
                  onClick={() => handleUserTypeSelect("practice-owner")}
                >
                  <Done
                    className={userType === "practice-owner" ? "selected" : ""}
                  />
                  YES
                </SelectionButton>
                <SelectionButton
                  onClick={() => handleUserTypeSelect("team-member")}
                >
                  <Done
                    className={userType === "team-member" ? "selected" : ""}
                  />
                  NO
                </SelectionButton>
              </SelectionsContainer>
              <NextStepButton
                onClick={() => setShowNextStep("show")}
                disabled={!userType}
              >
                Next
              </NextStepButton>
            </UserTypeSelection>
          ) : (
            <>
              {userType === "practice-owner" && isCountryAllowed && (
                <CreationFormContainer>
                  <WelcomeMessages>
                    <h1>
                      Welcome to DEXIS IS Connect Cloud{name && `, ${name}`}.
                    </h1>
                    <p>
                      Please provide the following information to let us create
                      your DEXIS IS Connect Cloud account.
                    </p>
                  </WelcomeMessages>
                  <div className="inputs-container">
                    <label>Practice Name</label>
                    <FormControl fullWidth margin="normal">
                      <Input
                        className="input"
                        id="name-input"
                        value={practiceName}
                        onChange={handleNameChange}
                        error={!!nameError}
                      />
                      {nameError && (
                        <FormHelperText error>{nameError}</FormHelperText>
                      )}
                    </FormControl>
                    <label>Country</label>
                    <Select
                      options={options}
                      onChange={handleCountryChange}
                      value={country}
                      isSearchable
                      placeholder="Select your country"
                      getOptionLabel={(option) => option.labelText}
                      getOptionValue={(option) => option.value}
                      formatOptionLabel={(option) => option.label}
                    />
                    {countryError && (<p className="error">{countryError}</p>)}
                    <p>
                      <i>
                        By clicking Create account I agree with the{" "}
                        {embedded === "true" ? (
                          <TermsOfUseLink onClick={handleTermsOfUsePopUp}>
                            Terms of Use
                          </TermsOfUseLink>
                        ) : (
                          <Link to="/terms-of-use" target="_blank">
                            Terms of Use
                          </Link>
                        )}
                      </i>
                    </p>
                  </div>
                  <NextStepButton
                    onClick={handleCreateLocation}
                    disabled={!formValid}
                  >
                    Create
                  </NextStepButton>

                  {openTermsOfUsePopUp && (
                    <TermsOfUsePopUp>
                      <CloseButton
                        onClick={() => setOpenTermsOfUsePopUp(false)}
                      >
                        <Close />
                      </CloseButton>
                      <TermsOfUsePage />
                    </TermsOfUsePopUp>
                  )}
                </CreationFormContainer>
              )}
              {userType === "practice-owner" && !isCountryAllowed && (
                <EmbeddedLocationContainer>
                  <RequestAccessForm countryCode={country?.value} />
                </EmbeddedLocationContainer>
              )}
              {userType === "team-member" && (
                <EmbeddedLocationContainer>
                  <img alt="error validation" src={redError} />
                  <EmbeddedMessagesContainer>
                    <b className="error">
                      Only administrator role can register a new practice in
                      DEXIS IS Connect Cloud
                    </b>
                  </EmbeddedMessagesContainer>
                </EmbeddedLocationContainer>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default LocationForm;