import React from "react";
import PremiumAd from "../../components/shared/PremiumAd/PremiumAd";
import { useSearchParams } from "react-router-dom";
import GetStarted from "../../components/shared/GetStarted/GetStarted";

const Subscription: React.FC = () => {
  const  [searchParams]  = useSearchParams();
  const getStartedParam = searchParams.get("getStarted");
  return <>
  {getStartedParam === "true" ? <GetStarted /> : <PremiumAd />}
  </>;
};

export default Subscription;
