import styled from "styled-components";
import { colors, media } from "../../../theme";

export const PlanCardContainer = styled.div`
  border: 1px solid ${colors["gray-5"]};
  border-radius: 5px;
  padding: 25px;
  max-width: 600px;
  min-width: 400px;
  margin: auto;
  text-align: left;
  flex-direction: row;
  display:flex;
  text-align: left;

  ${media.tablet} {
    padding: 10px 25px;
  }

  ${media.phoneL} {
    padding: 10px 15px;
  }

  ${media.phoneS} {
    padding: 10px;
  }
`;

export const PlanInfo = styled.div`
  flex: 2;
  /* padding: 10px; */
  margin: 0 0 0 15px;
  width: 50%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 10px;

  & button {
    background-color: ${colors["orange-1"]};
    color: white;
    margin-right: 8px;
    margin-top: 9px;
    border-radius: 6px;
  }

  &:hover button {
    background-color: ${colors["orange-1"]};
  }

  & button:disabled {
    background-color: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.26);
    pointer-events: none;
  }

  ${media.laptop} {
    & button {
      margin-right: 5px;
      margin-top: 6px;
      padding: 7px 14px;
    }
  }
`

