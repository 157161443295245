import React, { useCallback, useEffect, useState } from "react";
import { ILocation } from "../../services/api/models";
import Wait from "../../components/shared/Wait/Wait";
import { useNavigate } from "react-router-dom";
import useAuthService from "../../services/authService";
import { LoadingContainer, MainLayoutContainer } from "./index.styled";

import LocationForm from "../../components/shared/Locations/LocationForm/LocationForm";

const LocationManagement: React.FC = () => {
  const navigate = useNavigate();
  const [loadingLocations, setLoadingLocations] = useState(true);
  const [currentLocation, setCurrentLocation] = useState<ILocation>();
  const [hasError, setHasError] = useState(false);
  const authService = useAuthService();

  const fetchLocations = useCallback(async () => {
    try {
      const locationData = await authService.getCurrentLocation();
      const locationPlan = await authService.getLocationPlan();
      const userEmail = await authService.getUserEmail();
      const currentUser = locationData?.userLocations.find(
        (user) => user.user.emailAddress === userEmail
      );
      const isAdmin = currentUser?.role === "LocationAdministrator";

      if (
        isAdmin &&
        locationData &&
        !localStorage.getItem(`stayFree:${locationData.recordId}`) &&
        locationPlan.status === "Free"
      ) {
        navigate(`/subscription`);
      } else if (locationData) {
        setCurrentLocation(locationData);
        navigate(`/location/${locationData.recordId}`);
      } else {
        setLoadingLocations(false);
      }
    } catch (error: any) {
      console.error("Error fetching locations:", error);
      setLoadingLocations(false);
      setHasError(true);
    }
  }, [
    setCurrentLocation,
    setLoadingLocations,
    setHasError,
    navigate,
    authService,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchLocations();
    };
    fetchData();
  }, [fetchLocations]);

  if (loadingLocations) {
    return (
      <LoadingContainer>
        <Wait />
      </LoadingContainer>
    );
  }

  return (
    <MainLayoutContainer $hasLocation={!!currentLocation}>
      {!loadingLocations && !currentLocation && !hasError && (
        <LocationForm
          onLocationCreate={() => {
            fetchLocations();
            navigate("/subscription?getStarted=false");
          }}
        />
      )}
    </MainLayoutContainer>
  );
};

export default LocationManagement;
