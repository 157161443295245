import React, { useEffect, useState } from "react";
import {
  ProductContainer,
  ButtonContainer,
  PriceCaption,
  ActionButton,
  ErrorMessage,
} from "./ProductCard.styled";
import { finalizeSubscriptionWithoutBilling } from "../../../services/api/subscriptionService";
import {
  ILocation,
  ISubscriptionInfoWithoutBilling,
} from "../../../services/api/models";
import useAuthService from "../../../services/authService";
import getLoadedConfig from "../../../services/appConfigService";

interface ProductCardProps {
  handleFreeTrialCreated: (created: boolean) => void;
}

const ProductCard: React.FC<ProductCardProps> = ({
  handleFreeTrialCreated,
}) => {
  const [hasError, setHasError] = useState(false);
  const [currentLocation, setCurrentLocation] = useState<ILocation>();
  const { FreeTrialDuration } = getLoadedConfig();
  const { getCurrentLocation, reloadCurrentLocation } = useAuthService();

  useEffect(() => {
    const fetchLocationDetails = async () => {
      const locationsData = await getCurrentLocation();
      setCurrentLocation(locationsData);
    };
    fetchLocationDetails();
  }, [getCurrentLocation]);

  const createFreeTrail = async (
    subscriptionInfo: ISubscriptionInfoWithoutBilling
  ) => {
    try {
      await finalizeSubscriptionWithoutBilling(subscriptionInfo);
      const locationsData = await reloadCurrentLocation();
      setCurrentLocation(locationsData);
      handleFreeTrialCreated(true);
    } catch (error) {
      setHasError(true);
      console.error("Error finalizing the subscription: ", error);
    }
  };

  const handleCreateFreeTrial = async () => {
    if (currentLocation) {
      const subscriptionInfo: ISubscriptionInfoWithoutBilling = {
        locationId: currentLocation.recordId,
      };
      await createFreeTrail(subscriptionInfo);
    }
  };

  return (
    <>
      <ProductContainer>
        <h3>IS Connect Cloud Tier 1</h3>
        Perfect for practices looking to increase productivity
        <h2>
          $17.99{" "}
          <PriceCaption>
            {" "}
            /month* with {FreeTrialDuration} days free trial
          </PriceCaption>
        </h2>
        <hr />
        {hasError && (
          <ErrorMessage>
            <p>Error creating the free trial, please try again</p>
          </ErrorMessage>
        )}
        <ButtonContainer>
          <ActionButton onClick={handleCreateFreeTrial}>
            Get Started
          </ActionButton>
        </ButtonContainer>
      </ProductContainer>
    </>
  );
};

export default ProductCard;
