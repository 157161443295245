import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Wait from "../components/shared/Wait/Wait";
import Landing from "../pages/landing";
import PageHeader from "../components/shared/PageHeader/PageHeader";
import routes from "../routing";
import useAuthService, {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "../services/authService";
import Home from "../pages/home";
import LocationData from "../pages/location-data";
import LocationManagement from "../pages/location-management";
import usePersistedQueryParams, {
  setPersistedQueryParams,
} from "../shared/usePersistedAppQueryParams";
import TermsOfUsePage from "../pages/terms-of-use";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { MessageContainer } from "../components/shared/ErrorBoundary.styled";
import Footer from "../components/shared/PageFooter/PageFooter";
import RedirectPartner from "../pages/partners/redirectPartner/RedirectPartner";
import DownloadBridgePage from "../pages/download-bridge";
import Subscription from "../pages/subscription/Subscription";

const NotLoggedIn = () => {
  const { token } = usePersistedQueryParams();
  const [isTokenValid, setIsTokenValid] = useState(false);

  const authService = useAuthService();
  useEffect(() => {
    const fetchUserDetails = async () => {
      if (token) {
        if (authService.isTokenExpired(token)) {
          setPersistedQueryParams("token", null);
        } else {
          setIsTokenValid(true);
        }
      }
      if (isTokenValid && token) {
        setPersistedQueryParams("token", null);
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.delete("token");
        const newUrl = window.location.pathname + "?" + queryParams.toString();
        window.history.replaceState({}, document.title, newUrl);
        await authService.loginWithToken(token);
      }
    };
    fetchUserDetails();
  }, [token, authService, isTokenValid]);

  return (
    <Routes>
      {/* Prevent displaying the Login page while verifying the token to authenticate the user */}
      <Route path={routes.termsOfUse.path} element={<TermsOfUsePage />} />
      <Route
        path={routes.downloadBridge.path}
        element={<DownloadBridgePage />}
      />
      {!isTokenValid && <Route path="*" element={<Landing />} />}
    </Routes>
  );
};

const LoggedIn = () => {
  const { embedded, token } = usePersistedQueryParams();
  const authService = useAuthService();

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (token) {
        setPersistedQueryParams("token", null);
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.delete("token");
        const newUrl = window.location.pathname + "?" + queryParams.toString();
        window.history.replaceState({}, document.title, newUrl);
        if (authService.isTokenExpired(token)) {
          authService.logout();
        } else {
          authService.logoutRedirect(newUrl);
        }
      } else {
        const isUserAccountSet = await authService.isUserAccountSet();
        setUserAccountStatus(isUserAccountSet ? 1 : 0);
      }
    };
    fetchUserDetails();
  }, [authService, token]);

  const [userAccountStatus, setUserAccountStatus] = useState(-1);
  switch (userAccountStatus) {
    // Logged in user is not well set
    case 0:
      return (
        <MessageContainer>
          <p>
            <ErrorOutlineIcon />
            <span>Error retrieving user infos, please try again</span>
          </p>
        </MessageContainer>
      );
    // Logged in user is well set
    case 1:
      return (
        <>
          {embedded !== "true" && <PageHeader />}
          <Routes>
            <Route path={routes.location.path} element={<LocationData />} />
            <Route
              path={routes.locationManagement.path}
              element={<LocationManagement />}
            />
            <Route path={routes.termsOfUse.path} element={<TermsOfUsePage />} />
            <Route
              path={routes.redirectPartner.path}
              element={<RedirectPartner />}
            />
            <Route
              path={routes.downloadBridge.path}
              element={<DownloadBridgePage />}
            />
            <Route path={routes.subscription.path} element={<Subscription />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </>
      );
    // Case we don't have a status yet
    default:
      return (
        <MessageContainer>
          <Wait />
        </MessageContainer>
      );
  }
};

const RootComponent = () => {
  const { embedded } = usePersistedQueryParams();

  return (
    <>
      <UnauthenticatedTemplate>
        <NotLoggedIn />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <LoggedIn />
        {embedded !== "true" && <Footer />}
      </AuthenticatedTemplate>
    </>
  );
};

export default RootComponent;
