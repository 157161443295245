import styled from "styled-components";
import { OrangeButton } from "../../common/buttons.styled";
import { colors } from "../../../theme";

export const ProductContainer = styled.div`
  justify-content: center;
  align-items: center;
  min-height: 250px;
  width: 240px;
  border: 2px solid ${colors["gray-5"]};
  border-radius: 35px;
  margin-right: 10px;
  padding: 20px;
  margin-left: 50px;
  
`;

export const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;  
`;

export const ActionButton = styled(OrangeButton)`
   width: 60%;
`;

export const PriceCaption = styled.span`
  font-size: 8pt;
  font-weight: normal;
`;

export const ErrorMessage = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2px 6px;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 8px;
  font-weight: 600;

  & p {
    display: inline;
    margin: 0;
  }
`;