import { useEffect, useState } from "react";
import {
  MainContainer,
  DescriptionContainer,
  BenifitsContainer,
  ActionButton,
  ButtonContainer,
} from "./PremiumAd.styled";
import GetStarted from "../GetStarted/GetStarted";
import { useNavigate } from "react-router-dom";
import useAuthService from "../../../services/authService";
import { ILocation } from "../../../services/api/models";
import getLoadedConfig from "../../../services/appConfigService";

const PremiumAd: React.FC = () => {
  const [getStarted, setGetStarted] = useState(false);
  const { FreeTrialDuration } = getLoadedConfig();
const [currentLocation, getCurrentLocation] = useState<ILocation>();
  const authService = useAuthService();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLocation = async () => {
      const location = await authService.getCurrentLocation();
      getCurrentLocation(location);
    };
    fetchLocation();
  }, [authService]);

  const handleFreePlan = (): void => {
    localStorage.setItem(`stayFree:${currentLocation?.recordId}`, "true");
    navigate("/location");
  };

  return (
    <>
      {getStarted ? (
        <GetStarted />
      ) : (
        <MainContainer>
          <DescriptionContainer>
            <BenifitsContainer>
              <h3>
                DEXIS IS Connect Cloud allows you to access/view scans that were
                acquired through ScanFlow, anytime/anywhere through a web
                browser
                <br />
              </h3>
              This enables workflows to be started while the patient is
              chairside and completed later, even on another computer inside or
              outside of the practice.
              <br />
              <br />
              Whether you're at the office or at home, cloud access simplifies
              your workflow. No longer constrained by physical files or office
              hours, you can access patient scans effortlessly.
            </BenifitsContainer>
            <ButtonContainer>
              <ActionButton onClick={() => setGetStarted(true)}>
                Sign up for premium for {FreeTrialDuration}-days <br />
                at no charge
              </ActionButton>
              <ActionButton onClick={handleFreePlan}>
                Stay on free plan
              </ActionButton>
            </ButtonContainer>
          </DescriptionContainer>
        </MainContainer>
      )}
    </>
  );
};
export default PremiumAd;
