import React from "react";
import { MainLayout, TimeSpan } from "./index.styled";

const TermsOfUsePage: React.FC = () => {
  return (
    <MainLayout>
      <h1>Terms of Use</h1>

      <TimeSpan>Effective September 23, 2024</TimeSpan>

      <h3>General</h3>

      <p>
        These Terms of Use ("Terms") are concluded between you and Dental
        Imaging Technologies Corporation (DITC), 450 Commerce Dr Quakertown, PA,
        18951-3729 United States and/or any other affiliated company (defined as
        any other business entity, whether current or in the future, that
        directly, or through one or more intermediaries, controls or is
        controlled by or is under common control) of our group ("Company" or
        "Our" or "We")). If you choose to use this Website ("Site"), You hereby
        accept to abide by all the Terms and any other terms which are
        incorporated herein by reference.
      </p>

      <p>
        Company may change, suspend or discontinue any aspect of this Site at
        any time, including the availability of any feature, database, or
        content. Company may also impose limits on certain features and services
        or restrict access to parts or all of the Site without notice or
        liability. Additionally, Company may terminate and/or suspend access
        and/or ability to utilize the Site at any time and without notice,
        should any of the Terms be violated
      </p>

      <p>
        In order to access this Site, you must register as a User . You are
        responsible for maintaining the confidentiality of Your own password.
      </p>

      <p>
        Using the Site may be prohibited or restricted in certain countries. You
        are responsible for complying with the laws and regulations of the
        territory from which You access or use the Site.
      </p>

      <h3>Definitions</h3>

      <p>
        <strong>"Agreement"</strong> refers to these Terms and Conditions.
      </p>

      <p>
        <strong>"Content"</strong> includes but is not limited to text, videos,
        photos, x-rays, medical images, scans, files, images, data,
        presentations and/or other content. You retain all rights,
        responsibility and liability for all Content.
      </p>

      <p>
        <strong>"Services"</strong> are the ability to transfer, communicate,
        share data with and/or receive Content, information and data through a
        secure encrypted means between Users. While Content, information and
        data uploaded to the Site may remain available on the Site, it is not
        the intention of the Site to provide backup services for this Content,
        information and data. Such backup services are explicitly excluded from
        the Services.
      </p>

      <p>
        <strong>"User"</strong> is a licensed health care professional and whose
        professional license and/or accreditation remains current and in good
        standing.
      </p>

      <p>
        <strong>"You"</strong> shall mean the User.
      </p>

      <h3>Use of this Site</h3>

      <p>
        <u>Lawful Access</u>: By accessing this Site, You agree that You will
        not use this Site for any purpose that is unlawful or prohibited by
        these Terms. You shall not use this Site in any manner that could
        damage, disable, overburden, or impair any of Our servers or networks or
        the servers or networks of Our affiliated companies or of third parties
        connected to this Site. You shall not gain or try to gain unauthorized
        access to any area on this Site, which is protected by password, or any
        other computer systems or networks connected to any of Our servers
        through hacking or any other means. You shall abide by all applicable
        Federal and State laws and regulations.
      </p>

      <p>
        <u>Site for Professionals</u>: This Site is exclusively reserved for
        dental professionals. We aim to include accurate and up to date
        information, but make no representations or warranties, express or
        implied, as to the accuracy or completeness of the information provided
        on this Site and disclaim any liability for the use of this Site and any
        site linked to it. The information and materials provided on this Site
        are intended to be general and do not constitute advice in any specific
        patient case. Although the information at this Site is presented in good
        faith and believed to be correct, We make no representations or
        warranties as to the completeness or accuracy of the information.
        Information is supplied upon the condition that the persons receiving
        same will make their own determination as to its suitability for their
        purposes prior to use. In no event will We be responsible for damages of
        any nature whatsoever resulting from the use of or reliance upon
        information from this Site. Nothing on this Site that is made available
        by Company is intended to replace the services of a trained health
        professional or to be a substitute for medical advice of treatment
        providers.
      </p>

      <p>
        <u>Update to the Site</u>: We may change this Site, its Terms and/or any
        options on the Site at any time without notice but do not assume any
        responsibility to update it. Access and use of this Site and any website
        linked to from this Site and the content thereof is at the risk of
        Users.
      </p>

      <p>
        <u>Listing on the Site</u>: You may choose to be removed from the Site
        in Your discretion. To be removed please request removal from make a
        written request to Company in writing and allow up 10 business days for
        the removal to be finalized.
      </p>

      <h3>Service Fees</h3>

      <p>
        You are allowed to access the Site at no cost. However, should You wish
        to utilize certain Services features of the Site fees may apply. If fees
        apply, these will be set out on the Site and be subject to change in
        Company's discretion.
      </p>

      <h3>Communication</h3>

      <p>
        When utilizing the Services feature of the Site, it allows You to
        communicate with, receive information from and/or place Service requests
        with third parties. To enable such communication, You agree that other
        Users utilizing this Site are authorized (i) to retrieve your name,
        email and address, telephone number and/or (ii) send You a contact
        request via the Site. In no event will We be responsible for damages of
        any nature whatsoever resulting from the use of or reliance on such
        communication with third parties via the Site. We disclaim any and all
        liability for any direct or indirect damages arising out of any
        information You receive from a third party through this Site, or any
        communications or conduct that ensues from possessing that information.
      </p>

      <h3>Data storage and protection</h3>

      <p>
        By utilizing the Services as offered on the Site, the Company agrees to
        allow you to store Content and to share and/or receive Content with/from
        other Users via an encrypted method. The Content that is shared is
        encrypted at the source and decrypted at the intended receiving device.
        We utilize encryption and security methods that comply with the highest
        security standards.
      </p>

      <p>
        All Content that is uploaded to this Site or is transmitted through the
        Site via the Services may be stored for up to 12 months after the last
        date of Your activity. After the expiry of this 12 month period, the
        Content will be permanently deleted and will not be available nor
        retrievable.
      </p>
      <p>
        Irrespective of the foregoing, Company does not warrant continued and/or
        complete storage of the Content to Users who regularly use the Site.
        Under no circumstances the Site should be used as a backup application.
        It remains the Users responsibility to make the necessary arrangements
        to separately store a backup copy of any Content uploaded to the Site.
      </p>

      <h3>
        Utilizing the Services for Sharing of Patient Data and Other
        Transmissions
      </h3>

      <p>
        You may be subject to certain privacy regulations depending on the
        country in which You access the Site such as in the United States' the
        Health Insurance Portability and Accountability Act of 1996 (HIPAA) (42
        U.S.C. §§ 1320d et seq.), as amended by the Health Information
        Technology for Economic and Clinical Health (HITECH) Act (42 U.S.C. §§
        17921 et seq.), and their implementing regulations (45 C.F.R. Parts
        160-164). Company encourages You to fully review all applicable statutes
        and regulations to determine if they are applicable to You. IT IS YOUR
        RESPONSIBILITY TO ACT FULLY IN ACCORDANCE WITH ALL APPLICABLE LAWS,
        INCLUDING U.S. FEDERAL AND STATE LAWS, SPECIFICALLY THE PRIVACY AND
        SECURITY LAWS, SUCH AS HIPAA.
      </p>

      <p>
        Use of the Services enables Users to share and receive patient records
        at the discretion of the Users. Locally stored patient records are,
        depending on Your personal settings, generally visible or generally
        invisible for the other users of the same computer device on which You
        access the Site. Patient records contain patient data. Please note, that
        You alone decide with whom You want to share your patient records and
        that You may be required to obtain the respective patient's consent for
        sharing patient data with third parties. By sharing patient records
        using the Site, You represent and warrant that (i) You have obtained all
        contained patient data lawfully and with the patient's consent, (ii) You
        use such patient records only for lawful purposes and within the course
        of your own dental practice, and (iii) You are compliant with applicable
        federal and state patient rights, privacy, and data protection laws.
      </p>

      <p>
        You recognize and agree that when submitting Your patients' personal
        information to the Site, while We have safeguards in place to prevent
        unauthorized access or interception, there is no absolute guarantee of
        security.
      </p>

      <p>
        By accepting these Terms and Conditions, You agree if You are a United
        States licensed clinician to be bound by our HIPAA Business Associate
        Agreement (“BAA”) as set out in Annex 1. This BAA covers Company's
        relationship with You with regards to certain protected health
        information, in accordance with HIPAA.
      </p>

      <p>
        If You are practicing in a country of the European Union, UK, Norway,
        Lichtenstein, Iceland or Switzerland, by accepting these terms, You
        agree to be bound by our data processing agreement that you can find
        under Annex 2 to this Agreement ( “DPA”). This DPA covers our
        relationship with You when Company acts as a processor of personal data
        You control, in accordance with the EU General Data Protection
        Regulation (and for the UK, the UK GDPR & DPA 2018) or other applicable
        data protection law.
      </p>

      <p>
        Company reserves the right to delete the Content or parts of the Content
        of any transmission in relation to the Site or sent to Company that is
        in violation of applicable laws without prior notice. Company is
        entitled to log and store all data pertaining to any such transmission.
        To the extent any such transmission contains patient data or other
        personal and/or confidential data, You have the full responsibility for
        protecting such data. Please note that Company merely stores such data
        which is produced by You and for which You confirm patient’s consent is
        obtained in accordance with applicable laws. Company will access the
        patient records on the Site server only for the purpose of providing
        technical Site support to You and only upon your request. Although the
        communication with the Site is encrypted and Company provides reasonable
        efforts in order to protect data from unauthorized access, Company does
        not guarantee the security and confidentiality of such data. You agree
        to indemnify and hold Company harmless with respect to any claim based
        upon the content of your transmissions or the sharing of patient
        records.
      </p>

      <h3>Restricted Activity</h3>

      <p>
        By utilizing the Site, You agree that you will not do the following:
      </p>

      <ul>
        <li>violate any law, statute, and/or regulations;</li>

        <li>
          post or transmit Content that is false, misleading, offensive,
          defamatory and/or inappropriate material;
        </li>

        <li>
          post or provide Content that You are not the sole and exclusive owner
          to and/or for which You do not have the necessary and valid
          permissions (whether a license, consent, authorization, or release)
          allowing You to disclose the Content;
        </li>

        <li>
          communicate in an unprofessional manner, including but not limited to
          communicating in a manner that is deemed harassing and/or threatening;
          use of the Site in a manner that would be fraudulent and/or in any
          manner that would infringe on any third party's intellectual property
          rights;
        </li>

        <li>
          improperly (i.e., utilize for purposes outside of the scope of the
          Site) transfer, use and/or sell any data or Content that is
          transmitted through the Site; or
        </li>

        <li>
          discriminate against any User based on race, religious creed, color,
          national origin, ancestry, physical or mental disability, sex, gender,
          gender identity, age, medical condition, sexual orientation, military
          or veteran status and/or any other protected classification.
        </li>
      </ul>

      <h3>Disclaimers</h3>

      <p>
        Company does not guarantee or make any promise relating to the results
        from utilizing the Site. Company disclaims all representations and
        warranties, express, implied or statutory, not expressly set out in
        these Terms. When You select and utilize the services of another User
        You do so at Your own risk and Company does not warrant the accuracy,
        completeness or correctness, timeliness or usefulness of any Content
        transmitted through or provided by utilizing the Services or Site.
        Company makes no representations or warranties regarding the
        competencies, reliabilities, skill set, timeliness, safety and/or
        qualities of anyone listed on the Site. If You plan to utilize the
        Services, it is Your responsibility to confirm the qualifications,
        identity and licensures of any User with whom You communicate and/or
        transmit to or receive data from. The Company does not assume any duty
        or responsibility to ensure that the selected User is complying with
        applicable state and federal laws and regulations.
      </p>

      <p>
        You are solely responsible for Your interactions with other Users and in
        ensuring that all information, data and/or specifications needed to
        complete the agreed upon services have been provided. It is the User's
        duty and responsibility to review and approve the services provided by
        another User utilizing their independent medical judgement. The Company
        strongly recommends strict adherence to any product instructions for use
        and/or manuals. The Site and the services provided through the Site are
        not intended to replace the services of a trained healthcare
        professional or to be a substitute for the use of independent medical
        judgment. You have a duty to determine whether or not the service
        provided is suitable for a particular patient and circumstance. Company
        does not make any medical or clinical review of the services accessed
        through the Site. The User agrees that Company does not assume and
        specifically disclaims any liability for damages or injuries resulting
        from use of the Site.
      </p>

      <h3>Privacy</h3>

      <p>Company is committed to protecting your privacy.</p>

      <p>
        <u>Type of data collected and purpose of collection</u>: Company may
        collect Your e-mail address, name, surname or other information that You
        provide us. Such personal information may be used for product ordering,
        customer service and other services and to communicate with you. In some
        instances, such as part of a customer service enquiry, You may be
        obliged to provide us with some information in order for us to perform
        our services. In other instances, we may use Your contact details in
        order to send You product information, or Company information that we
        believe are of interest to You.
      </p>

      <p>
        Company will also have access to the data of your patients that You
        upload to the Site, including all data You may create / store on the
        Site (first name, surname, date of birth, gender, patient ID, intraoral
        scans, prescription forms, etc.). Any access to patient data by the
        Company will be in accordance with the terms and conditions of the DPA
        or BAA as applicable.
      </p>

      <p>
        <strong>Transfer of data to third parties:</strong> Company does not
        sell or disclose Your personal information to third parties without Your
        consent, except:
      </p>

      <ul>
        <li>
          To affiliates and third-party service providers to provide services
          (including support services) and information on our Site and related
          products, including marketing and advertising, and to support our
          business operations. We require these parties to handle Personal Data
          in accordance with our Privacy Notice.
        </li>

        <li>
          To authorities or third parties in order to enforce this Agreement or
          protect the rights, property or safety of Company, its affiliates,
          employees, users of the Site or the public. To governing regulatory
          authorities, including the US Food and Drug Administration, or as may
          otherwise be necessary for Company to comply with a legal obligation
          or demand.
        </li>
      </ul>

      <p>
        If Company decides to reorganize or divest its business through sale,
        merger, or acquisition, Company may share personal information about You
        with actual or prospective purchasers. We will require any actual or
        prospective purchasers to treat this personal information in a manner
        consistent with this notice. You can find all related affiliates here:{" "}
        <a href="https://www.envistaco.com/" target="_blank" rel="noreferrer">
          https://www.envistaco.com
        </a>
        .
      </p>

      <p>
        <strong>Your rights:</strong> Depending on the legislation applicable to
        you, You have the right to see and get a copy of personal information
        about You that we maintain as well as to ask us to make corrections to
        inaccurate or incomplete personal information about You. You have the
        right to receive data You have provided to us in a machine-readable
        format and to transmit that data to another controller. You may also
        request the erasure of Your Personal Data or the restriction of its
        processing, or object to the processing of personal information about
        You or withdraw your consent. To seek access to personal information
        about You, to file a concern, complaint, or request for correction, or
        to opt-out of particular programs, please contact our Privacy Office by
        emailing us at{" "}
        <a href="mailto:privacy@envistaco.com">privacy@envistaco.com</a>.
      </p>

      <p>
        <strong>International Data Transfers:</strong> Company may in
        exceptional cases transfer Your personal information to, or store it at,
        a destination outside the European Economic Area ("EEA") Switzerland or
        the UK. It may also be processed by staff operating outside the EEA,
        Switzerland or UK who work for us or for one of our suppliers. Such
        staff may be engaged in, among other things, the provision of support
        services. Where the Company has to transfer Your personal information to
        other third countries, we will use appropriate approved safeguards or we
        will seek Your explicit consent. For further information, please do not
        hesitate to contact us at{" "}
        <a href="mailto:privacy@envistaco.com">privacy@envistaco.com</a>.
      </p>

      <p>
        <strong>EEA countries:</strong> Your Local Data Protection Authority is
        responsible for making sure that privacy law is followed in the relevant
        countries. For more information about your privacy rights, or if you are
        not able to resolve a problem directly with us and wish to make a
        compliant, contact your local Data Protection Authority:{" "}
        <a
          href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
          target="_blank"
          rel="noreferrer"
        >
          http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
        </a>
        .
      </p>

      <p>
        <strong>Security Measures:</strong> The Company will implement
        appropriate technical and organizational measures to protect personal
        information against accidental or unlawful destruction or accidental
        loss, alteration, unauthorized disclosure or access by unauthorized
        third parties and against other unlawful forms of processing. However,
        we cannot guarantee that unauthorized third parties will not thwart our
        security systems and gain unlawful access to your personal information.
      </p>

      <p>
        Our privacy policy, which is incorporated to this Agreement by
        reference, is available in full here:{" "}
        <a
          href="https://envistaco.com/en/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          https://envistaco.com/en/privacy-policy
        </a>
        .
      </p>

      <h3>Warranty</h3>

      <p>
        Any implied warranties are excluded to the fullest permitted extent.
        Neither We nor any of Our affiliated companies, presented on this Site
        make any warranty with respect to such product, express or implied,
        written or oral, including guarantees of merchantability of fitness for
        a particular purpose, to the fullest permitted extent. We do not warrant
        the accuracy or timeliness of the materials on this Site and have no
        liability for any errors or omissions in the materials. We do not
        warrant the accuracy, relevance or timeliness of any information You
        receive or expect to receive from a third party through this Site.
        Neither do we warrant the completeness or integrity of the Content
        stored on the Site.
      </p>

      <h3>Limitation of Liability</h3>

      <p>
        Company shall not be liable for indirect, direct, incidental, special,
        punitive, consequential or any other monetary damages, fees, fines,
        penalties, or liabilities arising out of or relating to the use of the
        Site or the Services. Neither We nor any other party involved in
        creating, producing or delivering this Site or on any website linked to
        from this Site shall be liable in any manner whatsoever for any direct,
        incidental, consequential, indirect or punitive damages arising out of
        access, use or inability to use this Site or any website linked to from
        this Site, or any errors or omissions in the content thereof.
      </p>

      <p>
        Company disclaims any liability, express or implies and shall have no
        responsibility for any direct, indirect, punitive or other damages
        arising out of or in connection with any errors in professional
        judgement or practice in the utilization of the Services. The Services
        provided are under the control of the User and therefore the Company
        does not assume any liability whatsoever for damages arising thereof.
      </p>

      <p>
        Company is not liable for any damage resulting from the use of the Site,
        including any temporarily unavailability of the Site and/or the loss or
        accidental removal of all or part of the Content or User information.
      </p>

      <h3>Indemnity</h3>

      <p>
        You agree to indemnify and hold Us and our affiliates and their
        officers, directors, employees, and agents harmless from any and all
        claims, demands, losses, liabilities, and expenses (including attorneys'
        fees), arising out of or in connection with: (i) use of the Site or
        Services; (ii) Your breach or violation of any of these Terms; (iii)
        violation of the rights of any third party.
      </p>

      <h3>Intellectual Property Rights</h3>

      <p>
        All intellectual property rights on this Site are and shall remain Our
        exclusive property or of Our licensors, as applicable. All texts,
        graphics, interfaces, photographs, trademarks, logos, sounds, music,
        videos, artwork and computer code contained on this Site is owned,
        controlled or licensed by or to Us, and is protected by copyright,
        patent and trademark laws, and various other intellectual property
        rights and unfair competition laws.
      </p>

      <h3>Waiver and Severability</h3>

      <p>
        No failure or delay by either party in exercising any right under these
        Terms will constitute a waiver of that right. If any provision of these
        Terms are found to be deemed null and void, and the remaining provisions
        of this Agreement will remain in effect.
      </p>

      <h3>Governing law</h3>

      <p>
        If any part of this Agreement is found void and unenforceable, the
        remaining of the Agreement shall remain valid and enforceable according
        to its terms. The Company is entitled to change the terms of this
        Agreement; any and all changes shall become effective the earliest of
        You accepting them (e.g. at installation of an Update/Upgrade), or
        thirty (30) days after the communication of such change (by notice to
        you or by update of these terms). This Agreement shall be governed by
        the substantive laws of Switzerland. The exclusive place of jurisdiction
        shall be at the registered office of Company
      </p>

      <h3>Service 90-day Trial Terms & Conditions</h3>

      <p>
        Company is excited to provide a promotional 90-day free trial of the
        Service
      </p>

      <p>
        To access the free trial, customers complete enrolment in a Service
        subscription plan. DEXIS IS ScanFlow v.1.0.11 or above is required to
        run the Service; customers with previous versions of DEXIS IS ScanFlow
        can be upgraded free of charge.
      </p>

      <p>
        The trial period will begin upon completion of Service subscription plan
        enrolment. Service subscription may be cancelled at any time during the
        trial period. At the end of the trial period, You will automatically be
        billed for the selected subscription plan using the payment information
        provided during enrolment, if applicable. If you were not required to
        enter payment information at the time of subscribing, you will be give
        the option to continue with the subscription plan by entering payment
        information. The trial period account has a maximum storage size of 2
        gigabytes (GB) of data.
      </p>

      <p>
        All Service subscriptions will have a Term of twelve (12) months
        beginning at the time of first payment. Contracts will be automatically
        renewed at the end of each Term unless timely cancellation notice is
        provided. To cancel a subscription, the customer must confirm
        cancellation in the Service settings section before the end of the Term.
        The subscription will be discontinued upon the end of the then-current
        Term. No refund or credit will be provided for payments processed before
        the time of cancellation.
      </p>

      <p>
        Note that you may have an obligation under federal, state or local law
        to reflect discounts on product given pursuant to this promotion or any
        cost report forms submitted to federal or state government or private
        payer who provides reimbursement for that product.
      </p>

      <p>
        Limit of one (1) free trial per customer. Offer valid only in the United
        States.
      </p>

      <h2>Annex 1: HIPAA Business Associate Agreement</h2>

      <p className="pWithSpan">
        This Business Associate Agreement ("BAA") is entered into by and between
        the authorized users (as defined in the Agreement)
      </p>

      <span>("Covered Entity")</span>

      <p className="pWithSpan">
        and Dental Imaging Technologies Corporation (DITC), 450 Commerce Dr
        Quakertown, PA, 18951-3729 United States
      </p>

      <span>("Company")</span>

      <p>
        and/or any other affiliated company of our group from which you received
        the DEXIS IS Products (collectively "Business Associate") (collectively
        referred to herein as the "Parties"), effective as of the date the End
        User License Agreement is agreed upon by the Covered Entity.
      </p>

      <p>
        WHEREAS, the Parties have entered into one or more services agreements
        ("Services Agreement") whereby Business Associate performs certain
        functions, activities, or services for or on behalf of Covered Entity
        that involve the use or disclosure of Protected Health Information (as
        defined herein) and Electronic Protected Health Information (as defined
        herein); and
      </p>

      <p>
        WHEREAS, this BAA is intended to comply with the requirements of the
        Health Insurance Portability and Accountability Act of 1996 ("HIPAA")
        and implementing regulations, the Standards for Privacy of Individually
        Identifiable Health Information (the "Privacy Rule") the Security
        Standards for the Protection of Electronic Protected Health Information
        (the "Security Rule"), and the privacy, security and Breach Notification
        regulations of the Health Information Technology for Economic and
        Clinical Health ("HITECH") Act and the HIPAA Omnibus final rule
        (collectively, the "HIPAA Rules"), as amended from time to time.
      </p>

      <p>
        NOW, THEREFORE, in consideration of the Parties' continuing obligations
        under the Services Agreement between the Parties, the agreements herein,
        and other good and valuable consideration, the receipt and sufficiency
        of which is hereby acknowledged, the Parties agree as follows:
      </p>

      <ol>
        <li>
          <strong>
            <u>Definitions</u>
          </strong>

          <p>
            Except as otherwise defined herein, any and all capitalized terms in
            this BAA shall have the definitions set forth in the Privacy Rule or
            the Security Rule.
          </p>

          <ol className="upperAlphabeticList">
            <li>
              "<u>Breach</u>" has the meaning given to such term in 45 C.F.R. §
              164.402.
            </li>

            <li>
              "<u>Business Associate</u>" has the meaning set forth above.
            </li>
            <li>
              "<u>Covered Entity</u>" has the meaning set forth above.
            </li>

            <li>
              "<u>Designated Record Set</u>" has the same meaning as the term
              "designated record set" in 45 C.F.R. § 164.501 of the Privacy
              Rule.
            </li>

            <li>
              "<u>Electronic Protected Health Information</u>" ("EPHI") has the
              same meaning as the term "electronic protected health information"
              in 45 C.F.R. § 160.103 of the Security Rule, limited to the
              information created or received by Business Associate from or on
              behalf of Covered Entity.
            </li>

            <li>
              <u>
                Health Information Technology for Economic and Clinical Health
                ("HITECH") Act
              </u>
            </li>

            <li>
              "<u>HIPAA</u>" has the meaning set forth above
            </li>

            <li>
              "<u>Individual</u>" has the same meaning as the term "individual"
              in 45 C.F.R. § 160.103 of the Privacy Rule.
            </li>

            <li>
              "<u>Privacy Rule</u>" has the meaning set forth above.
            </li>

            <li>
              "<u>Protected Health Information ("PHI")</u>" has the same meaning
              as the term "protected health information" in 45 C.F.R. § 160.103
              of the Privacy Rule (including, without limitation, Electronic
              Protected Health Information), limited to the information created
              or received by Business Associate from or on behalf of Covered
              Entity.
            </li>

            <li>
              "<u>Required by Law</u>" has the same meaning as the term
              "required by law" in 45 C.F.R. § 164.103 of the Privacy Rule.
            </li>

            <li>
              "<u>Secretary</u>" means the Secretary of the Department of Health
              and Human Services or his or her designee.
            </li>

            <li>
              "<u>Security Incident</u>" has the same meaning as the term
              "security incident" in 45 C.F.R. § 164.304 of the Security Rule.
            </li>

            <li>
              "<u>Security Rule</u>" has the meaning set forth above.
            </li>

            <li>
              "<u>Unsecured PHI</u>" has the meaning given to such phrase in the
              Breach Notification Rule at 45 C.F.R. § 164.402.
            </li>
          </ol>
        </li>

        <li>
          <strong>
            <u>Obligations and Activities of Business Associate</u>
          </strong>

          <ol className="upperAlphabeticList">
            <li>
              Business Associate acknowledges and agrees that all PHI that is
              created or received by Covered Entity and used by or disclosed to
              Business Associate or created or received by Business Associate on
              Covered Entity's behalf shall be subject to this BAA.
            </li>

            <li>
              Business Associate agrees to not use or disclose PHI other than as
              permitted or required by this BAA or as Required by Law.
            </li>

            <li>
              Business Associate agrees to use appropriate safeguards to prevent
              use or disclosure of PHI other than as provided for by this BAA.
            </li>

            <li>
              Business Associate agrees to notify Covered Entity promptly
              following discovery of any Breach of Unsecured PHI. Business
              Associate will provide such information to Covered Entity as
              required in the Breach Notification Rule.
            </li>

            <li>
              Business Associate agrees to report to Covered Entity any use or
              disclosure of PHI not provided for by this BAA or any Security
              Incident of which it becomes aware.
            </li>

            <li>
              Business Associate agrees to ensure that any agent, including a
              subcontractor, to whom it provides PHI received from, or created
              or received by Business Associate for, or on behalf of, Covered
              Entity agrees in writing to substantially similar restrictions and
              conditions that apply through this BAA to Business Associate with
              respect to such information.
            </li>

            <li>
              To the extent Business Associate maintains PHI in a Designated
              Record Set, Business Associate will make such PHI available to
              Covered Entity within thirty (30) business days of a request by
              Covered Entity for access to such PHI. For avoidance of doubt,
              Covered Entity understands and agrees that Business Associate does
              not maintain any PHI in a Designated Record Set. If an Individual
              makes a request for access directly to Business Associate,
              Business Associate will within thirty (30) business days forward
              such request in writing to Covered Entity. Covered Entity will be
              responsible for making all determinations regarding the grant or
              denial of an Individual's request for PHI and Business Associate
              will make no such determinations. Only Covered Entity will release
              PHI to an Individual pursuant to such a request, unless Covered
              Entity directs Business Associate to do so.
            </li>

            <li>
              To the extent Business Associate maintains PHI in a Designated
              Record Set, Business Associate will provide such PHI to Covered
              Entity for amendment within thirty (30) business days of receiving
              a request from Covered Entity to amend an Individual's PHI. For
              avoidance of doubt, Covered Entity understands and agrees that
              Business Associate does not maintain any PHI in a Designated
              Record Set. If an Individual makes a request for amendment
              directly to Business Associate, Business Associate will within
              thirty (30) business days forward such request in writing to
              Covered Entity. Covered Entity will be responsible for making all
              determinations regarding amendments to PHI and Business Associate
              will make no such determinations unless Covered Entity directs
              Business Associate to do so.
            </li>

            <li>
              Within thirty (30) days of receiving a written request from
              Covered Entity, Business Associate shall provide to Covered Entity
              an accounting of the disclosures of the Individual's PHI in
              accordance with 45 C.F.R. § 164.528. If an Individual requests an
              accounting of Disclosures directly from Business Associate,
              Business Associate will forward the request and its record of
              Disclosures to Covered Entity within thirty (30) business days of
              Business Associate's receipt of the Individual's request. Covered
              Entity will be responsible for preparing and delivering the
              accounting to the Individual. Business Associate will not provide
              an accounting of its Disclosures directly to any Individual,
              unless directed by Covered Entity to do so.
            </li>

            <li>
              Business Associate agrees to make internal practices, books, and
              records, including policies and procedures and PHI, relating to
              the use and disclosure of PHI received from, or created or
              received by Business Associate on behalf of, Covered Entity,
              available to the Secretary, in a time and manner designated by the
              Secretary, for purposes of the Secretary determining Covered
              Entity's compliance with the Privacy Rule.
            </li>
          </ol>
        </li>

        <li>
          <strong>
            <u>Permitted Uses and Disclosures b</u>y Business Associate
          </strong>

          <ol className="upperAlphabeticList">
            <li>
              Except as otherwise limited by this BAA, Business Associate may
              use or disclose PHI to perform functions, activities or services
              for or on behalf of Covered Entity as contemplated in the Services
              Agreement, provided that such use or disclosure does not violate
              the Privacy Rule or the HITECH Act if done by Covered Entity.
            </li>

            <li>
              Except as otherwise limited by this BAA, Business Associate may
              use PHI for the proper management and administration of Business
              Associate or to carry out the present and/or future legal
              responsibilities of Business Associate.
            </li>

            <li>
              Except as otherwise limited by this BAA, Business Associate may
              disclose PHI for the proper management and administration of
              Business Associate, provided that disclosures are Required by Law,
              or Business Associate obtains reasonable assurances from the
              person to whom the PHI is disclosed that it will remain
              confidential and be used or further disclosed only as Required by
              Law or for the purpose for which it was disclosed to the person,
              and the person notifies Business Associate of any breaches in the
              confidentiality of the PHI.
            </li>

            <li>
              Business Associate may use PHI to report violations of law or
              other conduct to appropriate federal and state authorities or
              other designated officials, consistent with 45 C.F.R. §
              164.502(j)(1).
            </li>

            <li>
              Business Associate may use PHI to aggregate data as permitted by
              45 C.F.R. § 164.504(e)(2)(i)(B).
            </li>

            <li>
              Business Associate may use PHI to create de-identified information
              in accordance with 45 CFR § 164.514.
            </li>
          </ol>
        </li>

        <li>
          <strong>
            <u>Obligations of Covered Entity on Behalf of Business Associate</u>
          </strong>

          <ol className="upperAlphabeticList">
            <li>
              Covered Entity shall notify Business Associate of any
              limitation(s) in its notice of privacy practices within fifteen
              (15) business days of Covered Entity's receipt of the Individual's
              request in accordance with 45 C.F.R. § 164.520, to the extent that
              such limitation(s) may affect Business Associate's use or
              disclosure of PHI.
            </li>

            <li>
              Covered Entity shall notify Business Associate of any changes in,
              or revocation of, permission by an Individual to use or disclose
              PHI within fifteen (15) business days of Covered Entity's receipt
              of the Individual's request, to the extent that such changes may
              affect Business Associate's use or disclosure of PHI.
            </li>

            <li>
              Covered Entity shall notify Business Associate of any restriction
              to the use or disclosure of PHI that it has agreed to within
              fifteen (15) business days of Covered Entity agreeing to such
              restriction in accordance with 45 C.F.R. § 164.522, to the extent
              that such restriction may affect Business Associate's use or
              disclosure of PHI.
            </li>

            <li>
              Covered Entity shall not request that Business Associate use or
              disclose PHI in any manner that would not be permissible under
              HIPAA if done by a Covered Entity (unless permitted by HIPAA for a
              business associate).
            </li>

            <li>
              Covered Entity is responsible for implementing appropriate privacy
              and security safeguards to protect its PHI in compliance with
              HIPAA.
            </li>
          </ol>
        </li>

        <li>
          <strong>
            <u>
              Security Rule and HITECH Act Responsibilities of the Business
              Associate.
            </u>
          </strong>

          <p>
            With regard to its use and/or disclosure of ePHI, Business Associate
            hereby agrees to do the following:
          </p>

          <ol className="upperAlphabeticList">
            <li>
              Comply with the applicable requirements of the Security Rule.
            </li>

            <li>
              Require all of its subcontractors and agents that create, receive,
              maintain, or transmit ePHI on behalf of Business Associate to
              agree, in writing, to adhere to substantially similar restrictions
              and conditions concerning ePHI that apply to Business Associate
              pursuant to Section 5 of this BAA.
            </li>

            <li>
              Report to Covered Entity any Security Incident of which it becomes
              aware. Specifically, Business Associate will report to Covered
              Entity any successful unauthorized access, Use, Disclosure,
              modification, or destruction of ePHI or interference with system
              operations in an information system containing ePHI of which
              Business Associate becomes aware within thirty (30) business days
              of Business Associate learning of such Security Incident. The
              parties agree that this Section serves as notice by Business
              Associate to Covered Entity of the ongoing existence of attempted
              but Unsuccessful Security Incidents (as defined below), for which
              no additional reporting is required. For purposes of this BAA,
              "Unsuccessful Security Incidents" include but are not limited to
              activity such as "pings" and other broadcast attacks on Business
              Associate's firewall, port scans, unsuccessful log-on attempts,
              denials of service and any other attempts to penetrate such
              computer networks or systems that do not result in unauthorized
              access, use or disclosure of ePHI.
            </li>
          </ol>
        </li>

        <li>
          <strong>
            <u>Term and Termination</u>
          </strong>

          <ol className="upperAlphabeticList">
            <li>
              <em>Term.</em>The Term of this BAA shall in effect as of the
              Effective Date set forth above, and shall terminate when all the
              PHI provided by Covered Entity to Business Associate, or created
              or received by Business Associate for or on behalf of Covered
              Entity, is destroyed or returned to Covered Entity or, if it is
              infeasible to return or destroy the PHI, protections are extended
              to such information, in accordance with the termination provisions
              in this Section 6.
            </li>

            <li>
              <em>Termination for Cause.</em>If Covered Entity or Business
              Associate knows of a material breach or violation by the other
              party of any provision of this BAA, then the non-breaching party
              shall provide written notice of the breach or violation to the
              other party that specifies the nature of the breach or violation.
              The breaching party must cure the breach or end the violation
              within thirty (30) days after receipt of the written notice. In
              the absence of a cure reasonably satisfactory to the non-breaching
              party, then the non-breaching party may terminate this BAA between
              the parties.
            </li>

            <li>
              <em>Effect of Termination.</em>

              <ol className="lowerRomanList">
                <li>
                  Except as provided in paragraph (ii) of this Section, upon
                  termination of this BAA, for any reason, Business Associate
                  shall return or destroy all PHI received from Covered Entity,
                  or created or received by Business Associate for or on behalf
                  of Covered Entity. This provision shall apply to PHI that is
                  in the possession of subcontractors or agents of Business
                  Associate. Business Associate shall retain no copies of the
                  PHI.
                </li>

                <li>
                  In the event that Business Associate determines that returning
                  or destroying the PHI is infeasible, Business Associate shall
                  extend the protections of this BAA to such PHI and limit
                  further uses and disclosures of such PHI to those purposes
                  that make the return or destruction infeasible, for so long as
                  Business Associate maintains such PHI.
                </li>
              </ol>
            </li>
          </ol>
        </li>

        <li>
          <strong>
            <u>Notification</u>
          </strong>

          <p>
            With respect to notices pursuant to paragraph 2(D) above, notice
            shall be made by telephone to the telephone number associated with
            Covered Entity's account, followed promptly by a written notice as
            described below.
          </p>

          <p>
            Any notices required or provided for under this BAA shall be made in
            writing and shall be either personally delivered, mailed by first
            class mail or sent via facsimile or electronic mail to the
            appropriate individual identified below:
          </p>

          <span>
            <strong>For Covered Entity:</strong>
          </span>

          <span>Your address</span>

          <span>
            <strong>For Business Associate:</strong>
          </span>

          <span>
            Dental Imaging Technologies Corporation (DITC), 450 Commerce Dr
            Quakertown, PA, 18951-3729 United States or contact us at{" "}
            <a href="mailto:privacy@envistaco.com">privacy@envistaco.com</a>.
            Either Party may designate a different address in writing to the
            other.
          </span>
        </li>

        <li>
          <strong>
            <u>Regulatory References</u>
          </strong>

          <p>
            A reference in this BAA to a section in the Privacy Rule, the
            Security Rule or the HITECH Act means the section as in effect or as
            amended.
          </p>
        </li>

        <li>
          <strong>
            <u>Survival</u>
          </strong>

          <p>
            The respective rights and obligations of the Business Associate
            under Section 6 of this BAA shall survive the termination of this
            BAA.
          </p>
        </li>

        <li>
          <strong>
            <u>Interpretation</u>
          </strong>

          <p>
            Any ambiguity in this BAA shall be resolved to permit compliance
            with the HIPAA Rules. Any conflict between the terms of this BAA and
            any other agreement relating to the same subject matter shall be
            resolved so that the terms of this BAA supersede and replace the
            relevant terms of any such other agreement.
          </p>
        </li>

        <li>
          <strong>
            <u>Counterparts</u>
          </strong>

          <p>
            This BAA may be executed in counterparts which, when all signatures
            are assembled, shall have the same effect as a single,
            fully-executed agreement. Facsimile and photocopy signatures shall
            have the same binding effect as manual signatures.
          </p>
        </li>

        <li>
          <strong>
            <u>Severability</u>
          </strong>

          <p>
            The provisions of this BAA shall be severable, and if any provision
            of this BAA shall be held or declared to be illegal, invalid or
            unenforceable, the remainder of this BAA shall continue in full
            force and effect as though such illegal, invalid or unenforceable
            provision had not been contained herein.
          </p>
        </li>

        <li>
          <strong>
            <u>Governing Law</u>
          </strong>

          <p>
            Except to the extent that the HIPAA Rules or other federal law
            applies, this BAA and the obligations of the Parties hereunder will
            be governed by interpreted in accordance with the laws of the State
            of California.
          </p>
        </li>

        <li>
          <strong>
            <u>Effect</u>
          </strong>

          <p>
            This BAA amends, restates and replaces in its entirety any prior
            business associate agreement between the parties. This BAA
            supersedes all prior or contemporaneous written or oral contracts or
            understandings between the parties relating to their compliance with
            health information confidentiality laws and regulations, including
            HIPAA and HITECH.
          </p>
        </li>

        <li>
          <strong>
            <u>No Agency Relationship</u>
          </strong>

          <p>
            It is not intended that an agency relationship (as defined under the
            federal common law of agency) be established hereby expressly or by
            implication between Covered Entity and Business Associate under
            HIPAA or the Privacy Rule, Security Rule, or Breach Notification
            Rule. No terms or conditions contained in this BAA shall be
            construed to make or render Business Associate an agent of Covered
            Entity.
          </p>
        </li>
      </ol>

      <h2>
        ANNEX 2 <br /> DATA PROCESSING ADDENDUM
      </h2>

      <p>
        This Data Processing Addendum ("<strong>Addendum</strong>") is made by
        and between the <strong>User</strong> and the <strong>Company</strong>{" "}
        as defined above. For good and valuable consideration, the receipt and
        sufficiency of which are hereby acknowledged, <u>User</u> and Company
        agree as follows:
      </p>

      <ol>
        <li>
          <p>
            This Addendum shall apply to all Processing of Personal Data by the
            Company on behalf of the User, as described in Appendix 1 and
            Appendix 2 of this Addendum and which are an integrated part of this
            Addendum. In case of any direct conflict between this Addendum and
            the Terms and Conditions, the provisions of this Addendum shall
            prevail.
          </p>

          <p>
            Unless the context dictates otherwise, all terms which are not
            defined in this Addendum shall have the meaning ascribed to them in
            the Agreement. For the purpose of this Addendum,{" "}
            <strong>Data Processor</strong>, <strong>Data Subject</strong>,{" "}
            <strong>Personal Data Breach</strong>, and{" "}
            <strong>Processing</strong> (or equivalent terms used in Applicable
            Data Protection Laws) have the meanings ascribed to them or to the
            equivalent terms in the European Union ("<strong>EU</strong>")
            General Data Protection Regulation 2016/679 ("<strong>GDPR</strong>
            ") and in Applicable Data Protection Laws.{" "}
            <strong>Applicable Data Protection Laws</strong> means all
            applicable UK, Swiss, EEA, EU or EU Member State laws and
            regulations relating to the privacy, confidentiality, security or
            protection of Personal Data as replaced from time to time,
            including, without limitation, (i) the GDPR and EU Member State laws
            supplementing the GDPR, (ii) the EU Directive 2002/58/EC (e-Privacy
            Directive), and EU Member State laws implementing the e-Privacy
            Directive, including laws regulating the use of cookies and other
            tracking technologies. <strong>Personal Data</strong> means any
            information relating to an identified or identifiable natural person
            that is obtained or accessed by Company as contemplated by the
            Agreement and references to "personal data" should be read as
            references to "personal information" under other Applicable Data
            Protection Laws as necessary.
          </p>
        </li>

        <li>
          <p>
            In circumstances in which the Company Processes Personal Data as a
            Data Processor under the Agreement, Company shall:
          </p>

          <ol className="upperAlphabeticList">
            <li>
              Process Personal Data only in accordance with the documented
              instructions of the User, unless the Company is required to do
              otherwise by an applicable law, in which case the Company shall
              inform the User of the relevant legal requirement before
              Processing the Personal Data unless informing User is prohibited
              by the applicable law on important grounds of public interest;
            </li>

            <li>
              Comply with the Applicable Data Protection Laws in connection with
              the processing of Personal Data pursuant to this Agreement;
            </li>

            <li>
              Ensure that Company's employees or subcontractors authorized to
              Process the Personal Data have committed themselves in writing to
              confidentiality or are under an appropriate statutory obligation
              of confidentiality and do not transfer Personal Data to
              unauthorized third-parties;
            </li>

            <li>
              Take and maintain written technical, physical and organizational
              security measures necessary to ensure the protection of the
              Personal Data and that are appropriate to (i) the size, scope and
              type of Company's business; (ii) the type and sensitivity level of
              Personal Data; and (iii) the need for security and confidentiality
              of such Personal Data;
            </li>

            <li>
              Taking into account the nature of the Processing, assist the User,
              by appropriate technical, physical and organizational measures,
              insofar as this is possible, in fulfilling the User's obligation
              to respond to Data Subject's requests for exercising their rights
              under Applicable Data Protection Laws;
            </li>

            <li>
              Where required to do so by Applicable Data Protection Law notify
              the User upon becoming aware of the accidental or unlawful
              destruction, loss, alteration, unauthorised disclosure of, or
              access to Personal Data processed under this Agreement;
            </li>

            <li>
              Assist the User in complying with its obligations relating to data
              security, Personal Data Breaches and data protection impact
              assessments, taking into account the nature of the Processing and
              the information available to the Company;
            </li>

            <li>
              Make available to the User, for inspection on Company's premises
              only, the information necessary to demonstrate compliance with the
              obligations set out in this Addendum and allow for and contribute
              to audits conducted by the User or another auditor mandated by the
              User and approved by the Company, provided that the User gives the
              Company at least 30 days' prior written notice of its intention to
              carry out an audit. This notice shall include a detailed work plan
              for the audit. Any third party involved in the audit must agree to
              the Company's confidentiality undertakings and the User will bear
              all costs and expenses incurred by Company in connection with the
              audit; and
            </li>

            <li>
              The Company shall immediately inform the User if, in Company's
              opinion, an instruction provided by the User infringes applicable
              law.
            </li>
          </ol>
        </li>

        <li>
          User agrees that the Company may subcontract its Processing operations
          performed on behalf of the User under the Agreement. Prior to
          providing any subcontractor access to Personal Data, Company shall
          require such subprocessor to enter into a written agreement that
          imposes the same data protection obligations as set out in this
          Addendum. Upon the User's request, Company shall provide the User with
          the list of subprocessors authorized to access Personal Data in
          connection with the Agreement. Company shall inform the User of any
          intended changes concerning the addition or replacement of other
          subprocessors, thereby giving User the opportunity to object to such
          changes. Company will notify the User of any intended changes
          concerning the addition or replacement of its subprocessors and
          provide the User with the opportunity to object to such changes. If
          the User reasonably objects to a subprocessor, the User must inform
          the Company within seven (7) days. If the Company is unable to resolve
          the User's objection, either party may, upon notice and without
          liability, terminate the Processing operations that use the
          objected-to subprocessor.
        </li>

        <li>
          The User agrees that in case the Company transfers Personal Data
          outside of the European Economic Area or Switzerland or the United
          Kingdom for the purpose of fulfilling its obligations to the user
          under the Agreement, the Company will implemented appropriate
          safeguards for the transfer of the Personal Data in accordance with
          the Applicable Law Data Protection Laws. The User (as "data exporter")
          and the Company (as "data importer"), with effect from the
          commencement of any relevant transfer, hereby enter into the Standard
          Contractual Clauses set out in Commission Implementing Decision (EU)
          2021/914 of 4 June 2021, with Module Two (Transfer controller to
          processor selected including its annexes) as the same are revised or
          updated from time to time by the European Commission ("
          <strong>SCCs</strong>") and into the UK International Data Transfer
          Addendum to SCCs (“
          <strong>UK Addendum</strong>”) in respect of any transfer to the
          Company where such transfer would otherwise be prohibited by the GDPR
          in the absence of the SCCs. The SCCs and UK Addendum are incorporated
          by reference as if set out fully within this Addendum. Clauses 17 and
          18 to the SCCs shall be deemed to be pre-filled with the information
          on data processing described in this Addendum and in particular
          Appendix 1. The information on supervisory authorities in Section C
          shall be deemed to be pre-filled with the competent supervisory
          authority for the respective Contracting Party and the User agrees
          that the Company may engage sub-processors in accordance with Option 2
          of SCC Clause 9(a). Annex II to the SCC shall be deemed to be
          pre-filled with the following wording: "Taking into account the state
          of the art, the implementation costs and the nature, scope, context
          and purposes of the processing, as well as the varying likely risks to
          the rights and freedoms of natural persons, the Processor shall ensure
          a level of security appropriate to the risk, including, where
          relevant, the specific controls described in Article 32(1)(a) to (d)
          of the GDPR and including any other controls required under applicable
          data protection laws". Annex III to the SCC shall be deemed to be
          pre-filled with the information on data processing described in
          Appendix 1.
        </li>

        <li>
          This Addendum shall come into effect upon the effective date of the
          Agreement and shall expire or terminate concurrently therewith.
          Termination or expiration of this Addendum shall not discharge the
          Company from its confidentiality and data protection obligations until
          Personal Data is anonymized, returned to the User or destroyed.
        </li>
      </ol>

      <h2>
        APPENDIX 1 <br />
        DATA-PROCESSING
      </h2>

      <p>
        This Appendix constitutes the User's instruction to Company in
        connection with Company's Data Processing for the User and is an
        integrated part of the Data Processing Addendum.
      </p>

      <p>The processing of Personal Data</p>

      <ol>
        <li>
          Purpose and nature of the processing
          <ul className="discList">
            <li>
              To provide the User the access to and the use of the DEXIS IS
              Connect Cloud and organization of patient's dental data and
              treatment plans.
            </li>

            <li>
              To facilitate the functionality and use of the DEXIS IS Connect
              Cloud Storage.
            </li>

            <li>
              To anonymize in accordance with Applicable Data Protection Laws,
              personal data for further use for research, development or
              improvement of DEXIS products.
            </li>

            <li>
              To facilitate providing support to the User in relation to the use
              of the DEXIS IS Connect Cloud.
            </li>

            <li>
              To analyse performance and usage of the DEXIS IS Connect Cloud for
              statistical and improvement purposes.
            </li>
          </ul>
        </li>

        <li>
          Categories of Data Subjects
          <ul className="discList">
            <li>Dentists or employees of the User</li>

            <li>Patients treated by the User</li>
          </ul>
        </li>

        <li>
          Categories of Personal Data
          <ul className="discList">
            <li>Name, date of birth, address, gender, Case ID number</li>

            <li>IP address, software usage</li>
          </ul>
        </li>
        <li>
          Special categories of data (sensitive personal information)
          <ul className="discList">
            <li>Dental Treatment Plan</li>

            <li>Scans and other images of teeth</li>
          </ul>
        </li>

        <li>
          Locations of data processing
          <ul className="discList">
            <li>
              The Netherlands (for European customers), United States (for US
              customers), or as detailed in the Agreement
            </li>
          </ul>
        </li>

        <li>
          Duration of processing
          <ul className="discList">
            <li>
              Until deleted by User in accordance with the Applicable
              Legislation.
            </li>
          </ul>
        </li>
      </ol>

      <h2>
        APPENDIX 2 <br />
        Sub-processors
      </h2>

      <span>
        The Netherlands: <br />
        Microsoft Azure Service
      </span>
    </MainLayout>
  );
};

export default TermsOfUsePage;
