import styled from "styled-components";
import { media } from "../../../../src/theme";
import { OrangeButton } from "../../common/buttons.styled";

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  padding: 30px;
  min-height: calc(100vh - 15vh);

  height: auto;
  margin: 0 auto;

  ${media.tablet} {
    min-height: calc(100vh - 17vh);
  }
`;

export const BenifitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  margin: 0 auto;
  min-height: calc(100vh - 65vh);

  ${media.tablet} {
    min-height: calc(100vh - 60vh);
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin: 0 auto;
`;

export const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-content: space-between;
  margin: 0 auto;
`;

export const ActionButton = styled(OrangeButton)`
  width: auto;
  margin: 10px;
`;
