import http from "./http";
import getLoadedConfig from "../appConfigService";
import { ISubscriptionInfoWithoutBilling } from "./models";

export async function finalizeSubscriptionWithoutBilling(subscription: ISubscriptionInfoWithoutBilling) {
  const config = getLoadedConfig();
  const url = config.globalApiUrl + `subscriptions/finalizeWithoutBillingInfo`;
  const { data } = await http.post(url, subscription);
  return data;
}

export async function cancelSubscription(locationId: string) {
  const config = getLoadedConfig();
  const url = config.globalApiUrl + `subscriptions/${locationId}/cancelSubscription`;
  const { data } = await http.put(url);
  return data;
}