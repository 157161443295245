import { useEffect, useState, useRef } from "react";
import { AccountInfo } from "@azure/msal-browser";
import useAuthService from "../../../services/authService";
import {
  ButtonsHeaderContainer,
  Header,
  HeaderLocationName,
  HomeLinkLogo,
  StyledMenu,
  UserAvatar,
  MenuContainer,
} from "./PageHeader.styled";
import logo from "../../../assets/images/dexis-logo.png";
import {
  ListItemIcon,
  IconButton,
  Avatar,
  Divider,
  MenuItem,
} from "@mui/material";
import UserIcon from "../../svg-icons/UserIcon";
import MenuIcon from "../../svg-icons/MenuIcon";
import { ILocation } from "../../../services/api/models";
import PopupMenu from "../PopupMenu/PopupMenu";
import ExitIcon from "../../svg-icons/ExitIcon";
export interface IProps {
  account: AccountInfo | null;
}

export const PageHeader = () => {
  const menuButtonRef = useRef<null | HTMLButtonElement>(null);
  const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [activeMenu, setActiveMenu] = useState<"menu" | "avatar" | "">("");
  const [userDisplayName, setUserDisplayName] = useState("");
  const [userAvatar, setUserAvatar] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [location, setLocation] = useState<ILocation>();
  const [ShowPopupMenu, setShowPopupMenu] = useState(false);
  const { logout, getUserAvatar, getDisplayName, getUserEmail, getCurrentLocation, locationStorageKey } =
    useAuthService();

  const locationInfo = sessionStorage.getItem(locationStorageKey);
  const [displaySettings, setDisplaySettings] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const avatar = await getUserAvatar();
      setUserAvatar(avatar);
      const displayName = await getDisplayName();
      setUserDisplayName(displayName);
      const email = await getUserEmail();
      setUserEmail(email);
    };
    
    fetchUserDetails();
  }, [getUserAvatar, getDisplayName, getUserEmail]);

  useEffect(() => {
    const fetchLocationDetails = async () => {
      const locationsData = await getCurrentLocation();
      setLocation(locationsData);
    };
    fetchLocationDetails();
  }, [getCurrentLocation, locationInfo]);


  useEffect(() => {
    if ((window.location.pathname === "/location/") || (window.location.pathname === "/location")) {
      setDisplaySettings(true);
    }
  }, []);

  useEffect(() => {
      if (location !== undefined && menuButtonRef != null && displaySettings)
      {
          setDisplaySettings(false);
          setActiveMenu("menu");
          setAnchorElMenu(menuButtonRef.current);
          setShowPopupMenu(true);  
      }
  }, [location, menuButtonRef, displaySettings]);

  const openBooleanUser = Boolean(anchorElUser);

  const handlePopupMenuToggle = () => {
    setShowPopupMenu(!ShowPopupMenu);
  };

  return (
    <Header>
      <HomeLinkLogo to={"/"}>
        <img src={logo} alt="Dexis logo" />
      </HomeLinkLogo>
      {location && (
        <HeaderLocationName>
          Current location: {location?.name}
        </HeaderLocationName>
      )}
      <ButtonsHeaderContainer>
        {location && (
          <MenuContainer>
            <IconButton
              ref={menuButtonRef}
              className={activeMenu === "menu" ? "active-menu" : ""}
              onClick={(e) => {
                handlePopupMenuToggle();
                setAnchorElMenu(e.currentTarget);
              }}
            >
              <MenuIcon />
            </IconButton>
            <PopupMenu
              openMenu={ShowPopupMenu}
              setOpenMenu={setShowPopupMenu}
              currentLocation={location}
              anchorElMenu={anchorElMenu}
              userEmail={userEmail}
            />
          </MenuContainer>
        )}

        <UserAvatar>
          <Avatar
            className={
              activeMenu === "avatar"
                ? "active-menu avatar-logo"
                : "avatar-logo"
            }
            onClick={(e) => {
              setAnchorElUser(e.currentTarget);
              setActiveMenu("avatar");
            }}
          >
            {userAvatar}
          </Avatar>
          <StyledMenu
            anchorEl={anchorElUser}
            keepMounted
            open={openBooleanUser}
            onClose={() => {
              setAnchorElUser(null);
              setActiveMenu("avatar");
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem disabled={true}>
              <ListItemIcon>
                <UserIcon />
              </ListItemIcon>
              {userDisplayName || userEmail}
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => logout()}>
              <ListItemIcon>
                <ExitIcon />
              </ListItemIcon>
              Logout
            </MenuItem>
          </StyledMenu>
        </UserAvatar>
      </ButtonsHeaderContainer>
    </Header>
  );
};

export default PageHeader;
